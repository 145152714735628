import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import * as utils from './utils'
import * as utils_tracker from './utils_tracker'

import settings from './settings.json';

// import 'vue-class-component/hooks' // import hooks type to enable auto-complete

// https://blog.logrocket.com/how-to-write-a-vue-js-app-completely-in-typescript/

// Cookies -- https://www.npmjs.com/package/vue-cookies
// import VueCookies from 'vue-cookies'
// Vue.use(VueCookies)

import InfiniteLoading from 'vue-infinite-loading';
Vue.use(InfiniteLoading, { props: { spinner: 'bubbles'}});

// Bootstrap -- https://bootstrap-vue.js.org/docs
// import BootstrapVue from 'bootstrap-vue'
// Vue.use(BootstrapVue)
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.min.css'

import { ToastPlugin, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
Vue.use(ToastPlugin)

import { BModal } from 'bootstrap-vue'
import { BAlert } from 'bootstrap-vue'
import { BButton } from 'bootstrap-vue'
import { BFormRadio, BFormRadioGroup, BFormCheckbox, BFormCheckboxGroup } from 'bootstrap-vue'
import { BFormGroup } from 'bootstrap-vue'
import { BPopover } from 'bootstrap-vue'
import { BContainer, BRow, BCol } from 'bootstrap-vue'
import { BForm, BFormInput, BFormSelect } from 'bootstrap-vue'
import { BNavbar, BNavbarBrand, BNavbarToggle, BNavbarNav, BNavItem, BCollapse, BNavItemDropdown, BDropdown, BDropdownItem, BDropdownText, BDropdownDivider, BNavForm } from 'bootstrap-vue'
import { BSpinner } from 'bootstrap-vue'
import { BFormTextarea } from 'bootstrap-vue'

Vue.component('b-modal', BModal)
Vue.component('b-alert', BAlert)
Vue.component('b-button', BButton)
Vue.component('b-form', BForm)
Vue.component('b-form-radio', BFormRadio)
Vue.component('b-form-radio-group', BFormRadioGroup)
Vue.component('b-form-checkbox', BFormCheckbox)
Vue.component('b-form-checkbox-group', BFormCheckboxGroup)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-popover', BPopover)
Vue.component('b-container', BContainer)
Vue.component('b-row', BRow)
Vue.component('b-col', BCol)
Vue.component('b-form-input', BFormInput)
Vue.component('b-form-select', BFormSelect)
Vue.component('b-navbar', BNavbar)
Vue.component('b-navbar-brand', BNavbarBrand)
Vue.component('b-navbar-toggle', BNavbarToggle)
Vue.component('b-navbar-nav', BNavbarNav)
Vue.component('b-nav-item', BNavItem)
Vue.component('b-collapse', BCollapse)
Vue.component('b-nav-item-dropdown', BNavItemDropdown)
Vue.component('b-dropdown', BDropdown)
Vue.component('b-dropdown-item', BDropdownItem)
Vue.component('b-dropdown-text', BDropdownText)
Vue.component('b-dropdown-divider', BDropdownDivider)
Vue.component('b-nav-form', BNavForm)
Vue.component('b-spinner', BSpinner)
Vue.component('b-input-group', BInputGroup)
Vue.component('b-input-group-append', BInputGroupAppend)
Vue.component('b-form-textarea', BFormTextarea)

// v-markdown-editor
// https://github.com/nasa8x/v-markdown-editor
// import 'v-markdown-editor/dist/index.css';
// import Editor from 'v-markdown-editor'
// Vue.use(Editor);

// vue-tel-input
// removed because it was too huge so I changed to using vue-phone-number-input which is 1/2 the size (418k vs 222k), but was a wash cuz .css went way up
// decided to stay with vue-phone-number-input because it looks nicer
// import VueTelInput from 'vue-tel-input'
// Vue.use(VueTelInput)

// vue-plugin-load-script helps with external cloudinary upload widget script
// import LoadScript from 'vue-plugin-load-script';
// Vue.use(LoadScript);

// import { facebookPluginInstallFn } from './plugins/FacebookPlugin';
// Vue.use(facebookPluginInstallFn)

import vueHeadful from 'vue-headful';
Vue.component('vue-headful', vueHeadful);

// Google analytics - by passing router in, page views will be done automatically
//  But not hooked into utils_tracker because don't know how to get access to this.$gtag
const gaid: string = 'G-VP0KW5KXYW'
import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { id: gaid }
}, router);


// import InfiniteLoading from 'vue-infinite-loading';
// Vue.use(InfiniteLoading, { props: { spinner: 'bubbles'}});

import FlipCountdown from 'vue2-flip-countdown';
Vue.component('flip-countdown', FlipCountdown);

// vue-select for cool searchable dropdowns
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)


// my global css
import './global.scss'

Vue.config.productionTip = false;

const vue = new Vue({
  router,                 // so that we can access this.$route and this.$router in any Vue component (though not sure it's working)
  store,                  // so that we can access this.$store in any Vue component
  render: (h) => h(App),
}).$mount('#app');

utils_tracker.setVue(vue)

console.log(`App started - ${settings.AV}`)
