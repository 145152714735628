







































import { Component, Prop, Vue } from 'vue-property-decorator';
import { NFT } from '@/NFT'
import * as utils from '@/utils';
import * as utils_time from '@/utils_time';
import ModalWrapperComponent from '@/components/ModalWrapperComponent.vue';             // @ is an alias to /src

@Component({
  name: 'NFTGridComponent',
  components: { ModalWrapperComponent }
})
export default class NFTGridComponent extends Vue {
  // prop reactive member variables publically available to the html template
  @Prop({ default: null, required: true })
  public nft: NFT

  @Prop({ default: 0 })
  public index: number                 // in case it's ever useful for us to know where we are in the main list

  @Prop({ default: false })
  public selectMode: boolean

  @Prop({ default: null })
  public pieFilter: string

  // public reactive member variables available to the html template
  public showImage: boolean = false
  public chosen: boolean = false

  // public functions available to the html template
  public get loading(): boolean { return this.$store.state.loading }
  
  public get openseaURL(): string { return this.nft.getOpenseaURL() }
  public get looksRareURL(): string { return this.nft.getLooksrareURL() }

  public detailsClicked() {
    (this.$refs.NFTSingleComponent as any).show(this.nft.metadata.name, 'lg', { nft: this.nft, pieFilter: this.pieFilter })
  }

  public setSelectMode(selectMode: boolean) {
    this.selectMode = selectMode
  }
  public setChosen(chosen: boolean) {
    this.chosen = chosen
  }

  public choose() {
    this.chosen = true
    this.$emit('changed-chosen', this.nft.getIdAsString(), true)
  }

  public unchoose() {
    this.chosen = false
    this.$emit('changed-chosen', this.nft.getIdAsString(), false)
  }

  // private, non-reactive member variables

  // private functions not available directly to HTML template
  private async mounted() {
    if (this.$store.state.devmode) console.log(`${this.$options.name} mounted()`)
    if (this.$store.state.devmode) console.log(`Setting showImage to true in ${5 * this.index} ms`)
    setTimeout(() => { this.showImage = true; }, 5 * this.index)

  }

  private async beforeDestroy() { if (this.$store.state.devmode) console.log(`${this.$options.name} beforeDestroy()`) }

}
