const DEBUGGING: boolean = process.env.NODE_ENV === 'development'

import * as telegram from './telegram'
import * as utils from './utils'
import { BaseObject, MongoObject } from './_baseClasses'
import { ethers, providers, utils as etherUtilsModule, Contract as ContractModule } from "ethers";

export interface Edit {
  name: string,
  description: string,
  ucid: number,
  address: string,
  metamaskToken: string,
  ip: string,
  when: Date
}

// https://docs.opensea.io/docs/metadata-standards
export interface Metadata {
  name: string,
  image: string,
  attributes: Attribute[]
  description?: string,
  external_url?: string,
  edition?: number,
  traits?: any[],       // art blocks and lamelo NFTs use these

  image_data?: string,  // e.g. "<svg version='1.1' ... ></svg>"
  animation_url?: string
}

export interface Attribute {
  trait_type: string,       // e.g. Background
  value: string | number,   // e.g. Lavender
  display_type?: string,    // e.g. number, boost_number, boost_percentage, date
  rarity?: number           // as a percentage (what % of collection has this trait_type/value) 0-100
}

export class NFT extends MongoObject {
  protected static collectionName: string = 'nfts'           // IMPORTANT

  // static stuff for helper stuff
  private static errorsPerProject: { [ contractAddress: string ]: number } = {}   // keep track of how many errors in a row for a single project

  public contractAddress: string = null           // ERC721 blockchain thing (required) - unique on the blockchain  -- cannot change -- PREFERRED
  public symbol: string = null                    // ERC721 blockchain thing (optionally filled, and doesn't have to be unique) -- cannot change but not unique
  public token_id: string = null                  // ERC721 blockchain thing (required)

  public slug: string = null                      // only an OpenSea thing (optionally filled, and not sure I care about this) - can change
  public assetId: number = null                   // only an OpenSea thing (optionally filled, and not sure I care about this)

  public metadata: Metadata = null                // how do we know when to refetch this? one heuristic would be # of attributes (unrevealed ones tend to have few); another way would be rarity spread.

  public ours: boolean = null                     // true if these are OUR OWN NFTs that we generated
  public reserved: boolean = null                 // if one of OUR OWN NFTs that is minted, reserved, unrevealed and no art yet -- shuffling by app-nft-tools will make these 0-X
  public special: string = null                   // if one of OUR OWN NFTs that is minted, reserved, unrevealed and special like "red_envelope" -- shuffling by app-nft-tools will make these 0-X

  // rarity stuff
  public rarityScore?: number = null              // higher the better
  public numericalScore?: number = null           // higher the better
  public normalizedRarityScore?: number = null    // 1-100 with 100 the best
  public numTraits: number = null
  public rarityRank: number = null                // lower is better; deal with ties properly

  public owner: string = null                     // nice to keep track of?

  // Oct 8, 2022
  public edits: Edit[] = null
  public originalName: string = null
  public originalDescription: string = null

  // Sept 21, 2023
  public burned: boolean = null                   // we do it this way so that we can remember what got burned (vs permanaently mutating the metadata)

  public _temp: { attributesForProcessing?: Attribute[] } = {}

  // complex, class-based fields (require to be hydrated after loading from external sources like mongo or elastic)

  constructor(po?: object, contractAddress?: string, symbol?: string, token_id?: string, slug?: string, assetId?: number, metadata?: Metadata, rarityScore?: number, numericalScore?: number, owner?: string) {
    super()
    
    if (contractAddress != null) this.contractAddress = contractAddress.toLocaleLowerCase()
    if (symbol != null) this.symbol = symbol
    if (token_id != null) this.token_id = token_id
    if (slug != null) this.slug = slug
    if (assetId != null) this.assetId = assetId
    if (metadata != null) this.metadata = metadata
    if (rarityScore != null) this.rarityScore = rarityScore
    if (numericalScore != null) this.numericalScore = numericalScore
    if (owner != null) this.owner = owner.toLocaleLowerCase()
    if (po) this.injectPlainObject(po)
    this.hydrate()
  }

  protected hydrate(): void {
    super.hydrate()
  }

  // when sending to client
  public makeSafeForClient(): NFT {
    return this
  }

  public isSafe(): boolean {
    return true
  }


  // client-only stuff
  
  public getImageURL(): string {
    if (!this.metadata) return null
    if (!this.metadata.image) return null
    if (this.metadata.image.startsWith('ipfs://')) {
      // e.g. ipfs://QmUgFjqaSV6QK1QCJYtbeb5Wkz7UxWd4SRjjs9tLgiCQkR
      const pieces: string[] = this.metadata.image.split('//')
      const ipfsHash: string = pieces[1]
      // return `https://gateway.pinata.cloud/ipfs/${ipfsHash}`
      // return `https://ipfs.io/ipfs/${ipfsHash}`
      // return `https://cloudflare-ipfs.com/ipfs/${ipfsHash}`
      // return `https://ipfs.infura.io/ipfs/${ipfsHash}`
      // return `https://infura-ipfs.io/ipfs/${ipfsHash}`
      return `https://ipfs.io/ipfs/${ipfsHash}`
    }
    return this.metadata.image
  }

  // June 17, 2022: add proxy=true
  public getImageURLForThumbnail(maxw: number): string {
    const fullURL: string = this.getImageURL()
    if (!fullURL) return null
    const useProxy: boolean = !this.ours
    return `${utils.getURLprefix()}/api/utils/scaleimage?imgurl=${encodeURIComponent(fullURL)}&proxy=${useProxy}&maxw=${maxw || 400}`
  }

  public async fillMetaData(contract: ethers.Contract) {
    if (!this.token_id) return null
    const tokenURI: string = await contract.tokenURI(Number(this.token_id))
    try {
      const response: any = await utils.makeGetCall(tokenURI);
      if (response && response.data) {
        this.metadata = response.data
      } else {
        console.error(`fillMetaData() got bad response for ${tokenURI}`)
      }
    } catch (e) {
      console.error(`fillMetaData() got ${e} for ${tokenURI}`)
    }
  }

  public getAttributeValues(trait_type: string): (string | number)[] {
    // Sept 21, 2023: hack for burned - we do it this way so that we can remember what got burned
    if (trait_type === 'Slice' && this.burned) return [ 'Burned' ]

    const values: (string | number)[] = []
    if (!this.metadata || !this.metadata.attributes) return []
    const attributes: Attribute[] = this.metadata.attributes
    for (const attribute of attributes) if (attribute.trait_type === trait_type) values.push(attribute.value)
    return values
  }  

  public hasAttributeValue(trait_type: string, value: string | number): boolean {
    // Sept 21, 2023: hack for burned - we do it this way so that we can remember what got burned
    if (trait_type === 'Slice' && value === 'Burned' && this.burned) return true
    if (trait_type === 'Slice' && value !== 'Burned' && this.burned) return false

    const values: (string | number)[] = this.getAttributeValues(trait_type)
    return values.includes(value)
  }

  public isMissingAttribute(trait_type: string): boolean {
    if (!this.metadata || !this.metadata.attributes) return false
    const attributes: Attribute[] = this.metadata.attributes
    for (const attribute of attributes) if (attribute.trait_type === trait_type) return false
    return true
  }  

  public getOpenseaURL(): string {
    const contractAddress: string = this.contractAddress.includes('pieland') ? '0xd3cd44f07744da3a6e60a4b5fda1370400ad515b' : this.contractAddress
    return `https://opensea.io/assets/ethereum/${contractAddress}/${this.token_id}`
  }

  public getLooksrareURL(): string {
    const contractAddress: string = this.contractAddress.includes('pieland') ? '0xd3cd44f07744da3a6e60a4b5fda1370400ad515b' : this.contractAddress
    return `https://looksrare.org/collections/${contractAddress}/${this.token_id}`
  }  

  public nameWithoutExtra(): string {
    if (!this.originalName) return this.metadata.name
    if (this.originalName === this.metadata.name) return this.metadata.name
    return this.metadata.name.replace(` (${this.originalName})`, '')
  }

}
