import axios, { AxiosResponse, AxiosInstance, AxiosTransformer } from 'axios'
import store from '@/store';
import Vue from 'vue';
import settings from '@/settings.json';
import hash from 'hash.js'

// const VUE_APP_SERVER_API_URL_PREFIX_TO_USE: string = process.env.VUE_APP_SERVER_API_URL_PREFIX_TO_USE   // e.g. https://thedealslist.com or http://localhost:3001
// if (!VUE_APP_SERVER_API_URL_PREFIX_TO_USE) {
//   const e = 'VUE_APP_SERVER_API_URL_PREFIX_TO_USE is null from environment (check your .env.local)'
//   console.log(e)
//   throw(e)
// }

// "globals"
let AV: number = null                       // for detecting that the server has a new version and that the client needs updating
let msServerTimeAhead: number = null        // how far ahead is the server time
let I: string = null                        // server-reported IP for auth purposes

// March 11, 2024
export function redirectHomePage(): boolean {
  return true
}

// for API calls
export function getURLprefix(): string {
  let prefix: string = getWebURLprefix()
  if (process.env.VUE_APP_SERVER_API_URL_PREFIX_OVERRIDE && !process.env.VUE_APP_SERVER_API_URL_PREFIX_OVERRIDE.startsWith('_')) prefix = process.env.VUE_APP_SERVER_API_URL_PREFIX_OVERRIDE
  if (prefix.includes('localhost')) return 'http://localhost:3001'
  return prefix
  // return VUE_APP_SERVER_API_URL_PREFIX_TO_USE
}

export async function setMsTimeServerIsAhead(app: Vue) {
  if (msServerTimeAhead == null) {
    const url: string = getURLprefix() + `/api/utils/timesync`
    const response: any = await makeGetCall(url);

    // in case old version
    if (isClientOld()) {
      app.$router.go(0);
      app.$store.commit('setLoading', { loading: false });
      return;
    }

    if (response.data.msServerTimeAhead) {
      msServerTimeAhead = response.data.msServerTimeAhead
    }
  }
}

export function getMsTimeServerIsAhead(): number { return msServerTimeAhead || 0 }

export function getAndSetUniqueClientID(): number {
  let ucid: number = store.state.uniqueClientID
  if (!ucid) {
    ucid = Math.ceil(Math.random() * Number.MAX_SAFE_INTEGER)
    store.commit('setUniqueClientID', { uniqueClientID: ucid })
  }
  // console.log(`ucid: ${ucid}`)
  return ucid
}

// e.g. https://localhost:8080
// e.g. https://thebestdeals.com
// e.g. https://www.thebestdeals.com
export function getWebURLprefix(): string {
  return 'https://' + window.location.host
}

// e.g. localhost
// e.g. thebestdeals.com
export function getCurrentHostname(): string {
  let hostname: string
  hostname = window.location.host.includes(':') ? window.location.host.split(':')[0] : window.location.host
  if (hostname.includes('www.')) hostname = hostname.replace('www.', '')
  return hostname
}

// add this to all Axios get calls so that ISO-formatted dates get transformed into Date objects
// this is the complement of express's reviver thing where I use a dateReviver
const dateTransformer: any = {
  transformResponse: (axios.defaults.transformResponse as AxiosTransformer[]).concat(transformAllDatesRecursively)
}

// my own invention
function transformAllDatesRecursively(obj: any, headers: any): any {
  if (obj === null) return obj

  if (typeof obj !== 'object') {
    // if (typeof obj === 'string' && (/^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d.\d\d\dZ$/.test(obj))) return new Date(obj)
    if (typeof obj === 'string' && (/^\d\d\d\d-\d+-\d+T\d+:\d+:\d+.\d+Z$/.test(obj))) return new Date(obj)   // more flexible, needed for ES results
    if (typeof obj === 'string' && (/^\d\d\d\d-\d+-\d+T\d+:\d+:\d+Z$/.test(obj))) return new Date(obj)       // more flexible, needed for ES results
    return obj      // base case
  }

  if (Array.isArray(obj)) {
    // array
    for (let i = 0; i < obj.length; i++) {
      obj[i] = transformAllDatesRecursively(obj[i], headers)
    }
  } else {
    // dictionary
    const keys: string[] = Object.keys(obj)
    for (const k of keys) {
      obj[k] = transformAllDatesRecursively(obj[k], headers)
    }
  }
  return obj
}

export function isClientOld(): boolean {
  return AV && (AV > settings.AV)
}

// could add UA too if I want even more, or if I just want to play cat & mouse
function makeToken(t: number): string {
  return hash.sha1().update(t.toString() + I).digest('hex')
}

async function getAuthenticatedHeader(waitForIP: boolean): Promise<object> {
  // wait until IP is set before doing proceeding, unless timesync is being called, or else token will be wrong
  let numTries: number = 0
  while (!I && waitForIP && numTries < 50) {
    await sleep(100)
    numTries++
  }

  const t = new Date().getTime()
  const header: any = {
    AV: settings.AV,
    hostname: getCurrentHostname(),
    language: navigator.language,
    t,
    msServerTimeAhead,
    token: makeToken(t),
    ucid: getAndSetUniqueClientID()
  }
  // note, setting header values to null ends up transmitting them as "[object Object]" so I just don't add them at all if null
  if (store.state.user) header.userId = store.getters.userId
  if (store.state.user) header.username = store.getters.username
  if (store.state.sessionId) header.sessionId = store.state.sessionId

  // hack so that my local dev client can get through to prod server until oct 17 2022
  if (process.env.VUE_APP_SERVER_API_URL_PREFIX_OVERRIDE && !process.env.VUE_APP_SERVER_API_URL_PREFIX_OVERRIDE.startsWith('_')) header.token = 'tpietland'

  return header
}

export async function makeGetCall(url: string): Promise<any> {
  const axiosInstance: AxiosInstance = axios.create({
    timeout: 120000,
    headers: await getAuthenticatedHeader(url.endsWith('/timesync') ? false : true)
  })
  if (store.state.devmode) console.log(`makeGetCall(${url})`)
  const response: any = await axiosInstance.get(url, dateTransformer)
  AV = Number(response.headers.AV || response.headers.av)
  I = response.headers.I || response.headers.i
  return response
}

export async function makeGetCallForBinaryData(url: string): Promise<any> {
  const axiosInstance: AxiosInstance = axios.create({
    timeout: 60000,
    headers: await getAuthenticatedHeader(url.endsWith('/timesync') ? false : true),
    responseType: 'arraybuffer'
  })
  if (store.state.devmode) console.log(`makeGetCall(${url})`)
  const response: any = await axiosInstance.get(url, dateTransformer)
  AV = Number(response.headers.AV || response.headers.av)
  I = response.headers.I || response.headers.i
  return response
}

export async function makePostCall(url: string, data: object): Promise<any> {
  const axiosInstance: AxiosInstance = axios.create({
    timeout: 60000,
    headers: await getAuthenticatedHeader(url.endsWith('/timesync') ? false : true)
  })
  if (store.state.devmode) console.log(`makePostCall(${url})`)
  if (store.state.devmode) console.dir(data)
  const response: any = await axiosInstance.post(url, data, dateTransformer)
  AV = Number(response.headers.AV || response.headers.av)
  I = response.headers.I || response.headers.i
  return response
}

export async function makePutCall(url: string, data: object): Promise<any> {
  const axiosInstance: AxiosInstance = axios.create({
    timeout: 60000,
    headers: await getAuthenticatedHeader(url.endsWith('/timesync') ? false : true)
  })
  if (store.state.devmode) console.log(`makePutCall(${url})`)
  const response: any = await axiosInstance.put(url, data, dateTransformer)
  AV = Number(response.headers.AV || response.headers.av)
  I = response.headers.I || response.headers.i
  return response
}

export async function makeDeleteCall(url: string): Promise<any> {
  const axiosInstance: AxiosInstance = axios.create({
    timeout: 60000,
    headers: await getAuthenticatedHeader(url.endsWith('/timesync') ? false : true)
  })
  if (store.state.devmode) console.log(`makeDeleteCall(${url})`)
  const response: any = await axiosInstance.delete(url, dateTransformer)
  AV = Number(response.headers.AV || response.headers.av)
  I = response.headers.I || response.headers.i
  return response
}

export function getLuma(c: string): number {
  if (c.startsWith('#')) c = c.substring(1)
  const rgb: number = parseInt(c, 16);   // convert rrggbb to decimal
  // tslint:disable-next-line: no-bitwise
  const r: number = (rgb >> 16) & 0xff;  // extract red
  // tslint:disable-next-line: no-bitwise
  const g: number = (rgb >> 8) & 0xff;  // extract green
  // tslint:disable-next-line: no-bitwise
  const b: number = (rgb >> 0) & 0xff;  // extract blue

  const luma: number = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709, and will be 0-255
  return luma;
}

export function toast(v: Vue, title: string, msg: string, autoHideDelayInMS?: number, forceVariant?: string, toaster?: string): void {
  console.log(`Toasting ${title}/${msg}`)
  if (!v) {
    console.log(`Can't toast without v`)
    return
  }

  const titleLower: string = title.toLocaleLowerCase()
  let variant: string = 'info'                                    // blue
  if (titleLower.includes('error')) variant = 'danger'            // red
  else if (titleLower.includes('missing')) variant = 'danger'     // red
  else if (titleLower.includes('invalid')) variant = 'danger'     // red
  else if (titleLower.includes('success')) variant = 'success'    // green
  else if (titleLower.includes('warning')) variant = 'warning'    // orange

  if (forceVariant) variant = forceVariant

  // do v.$root.$bvToast instead of v.$bvToast so that toast persists across router changes
  const options: any = { title, variant, solid: true, appendToast: true, noCloseButton: false, autoHideDelay: autoHideDelayInMS || 3500 }
  if (toaster) options.toaster = toaster    // e.g. b-toaster-bottom-left
  v.$root.$bvToast.toast(msg, options)
}

export function hideToasts(v: Vue): void {
  v.$root.$bvToast.hide()
}

export function isValidEmail(email: string): boolean {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}
export function isValidPassword(password: string): boolean {
  return password && password.length >= 4
}
export function isValidName(name: string): boolean {
  return name && name.length > 0
}
export function capitalizeFirstLetter(s: string) {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

// eg +17179288080 to 717-928-8080
export function prettyE164toUS(phone: string): string {
  phone = phone.replace('+1', '')
  return `${phone.substring(0, 3)}-${phone.substring(3, 6)}-${phone.substring(6, 10)}`
}

// a good alternative to toast because toasts go away when use close a modal
export function showTopAlert(variant: string, message: string, secToAutoDismiss: number): void {
  store.commit("setTopAlertVariant", { topAlertVariant: variant });
  store.commit("setTopAlertMessage", { topAlertMessage: message });
  store.commit("setTopAlertShowing", { topAlertShowing: secToAutoDismiss ? secToAutoDismiss : true });
}
export function showBotAlert(variant: string, message: string, secToAutoDismiss: number): void {
  store.commit("setBotAlertVariant", { botAlertVariant: variant });
  store.commit("setBotAlertMessage", { botAlertMessage: message });
  store.commit("setBotAlertShowing", { botAlertShowing: secToAutoDismiss ? secToAutoDismiss : true });
}

// example usage: await sleep(100)
export function sleep(ms: number): Promise<void> {
  return new Promise<void>((resolve) => setTimeout(resolve, ms));
}

// https://gist.github.com/sindresorhus/1993156
export function stripScripts(s: string): string {
  const b: HTMLOptionElement = new Option()
  b.innerHTML = s;
  const scriptElements = b.getElementsByTagName('script')
  for (const se of scriptElements) {
    se.parentNode.removeChild(se)
  }
  return b.innerHTML
}

export function saveUTMsToLocalStorage(query: any) {
  if (!query) return
  if (query.utm_source) localStorage.setItem('utm_source', query.utm_source as string)
  if (query.utm_campaign) localStorage.setItem('utm_campaign', query.utm_campaign as string)
  if (query.utm_medium) localStorage.setItem('utm_medium', query.utm_medium as string)
  if (query.utm_content) localStorage.setItem('utm_content', query.utm_content as string)
  if (query.utm_term) localStorage.setItem('utm_term', query.utm_term as string)

  // if (query.utm_source) console.log(`Saved utm_source ${query.utm_source} to localStorage`)

}

export function formatCurrency(amount: number, currency: string): string {
  // console.log(`formatCurrency(${amount}, ${currency}) with ${navigator.language}`)

  let result: string = new Intl.NumberFormat(navigator.language, { style: 'currency', currency }).format(amount)

  // hack for CAD (not sure why Intl.NumberFormat fails here)
  if (currency === 'CAD' && !result.startsWith('C')) result = 'C ' + result

  return result
}

export function makePrettyTitleForURL(s: string): string {
  return s.replace(/[\#$%\^&\*{}=\_`~()]/g, '').replace(/[\s!.,/;:]+/g, '-').replace(/--/g, '-')
}

export function formatGiantNumber(n: number): string {
  if (n > 1000000) return `${Math.round(n / 1000000)}M`
  if (n > 1000) return `${Math.round(n / 1000)}K`
  return n.toLocaleString()
}

export function abbreviatedLongString(s: string, maxLength: number): string {
  const MIDDLE_PAD = '......'
  const threeQuarterLength: number = Math.floor(maxLength * 3 / 4) - MIDDLE_PAD.length / 2
  const quarterLength: number = Math.floor(maxLength / 4) - MIDDLE_PAD.length / 2
  if (s == null) return null;
  s = s.replace(/\n/g, '')
  if (s.length < maxLength) return s;
  return s.substring(0, threeQuarterLength) + MIDDLE_PAD + s.substring(s.length - quarterLength, s.length)
}

export function getLastWord(s: string): string {
  if (!s) return null
  const pieces: string[] = s.split(' ')
  return pieces[pieces.length - 1]
}

export function recreateURL(path: string, query: object) {
  // path e.g. /listings/auctions
  // query e.g. {"sortOrder":"EndDate_ASC","auctionId":"60f0a280e23d6f2bb8fcfe9f"}
  // returns /listings/auctions?sortOrder=EndDate_ASC&auctionId=60f0a280e23d6f2bb8fcfe9f
  let url: string = path
  const qsNames: string[] = Object.keys(query)
  for (const qsName of qsNames) url += `&${qsName}=${query[qsName]}`
  return url.replace('&', '?')
}

export function isTouchDevice() {
  let isTouch: boolean = (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    ((navigator as any).msMaxTouchPoints > 0));
  if (isTouch) return true

  const UA = navigator.userAgent;
  isTouch = (
      /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
      /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
  );

  return isTouch

}

export function reverseString(str: string): string {
  let newString: string = "";
  for (let i = str.length - 1; i >= 0; i--) newString += str[i];
  return newString;
}

(async () => {
})()
