import * as utils from './utils'
import * as utils_sendgrid from './utils_sendgrid'
import { User } from './User'

const DEBUGGING = process.env.NODE_ENV === 'development'

export type emailTypes = 'confirmEmail' | 'passwordReset' | 'invite' | 'marketing'

export class NFTUser extends User {
  protected static ALLOW_UNEXPECTED_FIELDS: boolean = false   // cuz we set User to true

  public ENSname: string = null
  public address: string = null
  public lastChainId: string = null
  public lastBalance: number = null

  // we don't need to send these to segment because segment captures these automatically, but we capture these ourselves too for our own DB
  public utm_source: string = null
  public utm_campaign: string = null
  public utm_medium: string = null
  public utm_content: string = null
  public utm_term: string = null

  // complex, class-based fields (require to be hydrated after loading from external sources like mongo or elastic)

  // client-only field not meant to be stored in db - utils_mongo.insertOne() and utils_mongo.insertMany() are instrumented to do this
  public _temp: {} = {}

  constructor(po?: object) {
    super(null)
    if (po) this.injectPlainObject(po)
    this.hydrate()
  }

  // on both client and server side
  public getTraitObjectForSegment(): any {
    return {
      // segment reserved traits
      createdAt: this.created.toISOString(),
      email: this.email,
      id: this.getIdAsString(),

      // our own traits
      address: this.address,
      emailVerified: this.emailVerified,
      invitedBy: this.invitedBy,
      numInvited: this.invited.length,
      lastSeen: (this.lastSeen) ? this.lastSeen.toISOString() : null,
      source: this.source,
      fullyRegistered: this.fullyRegistered,
    }
  }

  public static getDescriptionFromEmailType(emailType: emailTypes): string {
    if (emailType === 'confirmEmail') return 'Email confirmations'
    if (emailType === 'invite') return 'Invitations to join'
    if (emailType === 'passwordReset') return 'Password reset requests'
    if (emailType === 'marketing') return 'Marketing emails'
    return emailType
  }

  public isSafe(): boolean {
    if (!super.isSafe()) return false
    return true
  }

}
