




































import { Component, Vue } from 'vue-property-decorator';
import { NFTUser } from '@/NFTUser'
import * as utils from '@/utils';
import * as utils_auth from '@/utils_auth';
import * as utils_tracker from '@/utils_tracker';
import ModalWrapperComponent from '@/components/ModalWrapperComponent.vue';             // @ is an alias to /src
import SocialBarComponent from '@/components/SocialBarComponent.vue';             // @ is an alias to /src

@Component({
  name: 'Roadmap',
  components: { ModalWrapperComponent, SocialBarComponent }
})
export default class Roadmap extends Vue {
  // prop reactive member variables publically available to the html template

  // public reactive member variables available to the html template  
  public get loading(): boolean { return this.$store.state.loading }
  public get isAuthenticated(): boolean { return this.$store.getters.isAuthenticated }
  public get user(): NFTUser { return this.$store.getters.userObject }

  public userClickedButton() {
    window.location.href = "https://pielandworld.medium.com/mapping-out-pieland-cad02c16de1";
  }

  // private, non-reactive member variables

  // private functions not available directly to HTML template
  private async mounted() {
    if (this.$store.state.devmode) console.log(`${this.$options.name} mounted()`)
    utils_tracker.page(this.$options.name)
  }

  private async beforeDestroy() { 
    if (this.$store.state.devmode) console.log(`${this.$options.name} beforeDestroy()`)
  }

}

