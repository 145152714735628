







// How to use this modal component (you can find example in NavbarComponent.vue)
// Minimal=
//  1. import this in your .vue file and also add to list of vue subcomponents to your component
//  2. add this to your html template somewhere. e.g.: <ModalWrapperComponent ref='RegisterFormModalComponent' innerComponentName='RegisterFormComponent' :startingInnerComponentProps="{ invitingMode: false }" />
//  3. add @click to an element in your html template that calls a local function (e.g. userChoseRegister) that calls this show() function, passing in dynamicProps if appropriate
//  4. optionallly listen for changed-modalshow events to know when the modal was closed
//  5. the dynamic component inside here needs to be imported and defined here (parent doesn't need to import, just needs to know the name)
//  6. static props can be sent to the dynamic component using the startingInnerComponentProps props, and dynamic props can be sent when calling the .show() of this function
//  7. inner components can define onShow() to do stuff when shown and be passed the dynamicProps by ModalWrapperComponent

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import * as utils from '../utils';
import * as utils_auth from '../utils_auth';
import { RawLocation } from 'vue-router';
import { globalUC } from '../UserCache';

// we use dynamic components to swap out which component is shown
//  * https://vuejs.org/v2/guide/components.html#Dynamic-Components
//  * https://stackoverflow.com/questions/43658481/passing-props-dynamically-to-dynamic-component-in-vuejs
@Component({
  name: 'ModalWrapperComponent',
  components: {
    // we do the imports this way to avoid circular references, and since some of the below components in turn import this ModalWrapperComponent, we need to do this
    // Docs: https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
    // also good for lazy-loading: https://vueschool.io/articles/vuejs-tutorials/lazy-loading-and-code-splitting-in-vue-js/
    GenericConfirmationComponent: () => import('@/components/GenericConfirmationComponent.vue'),
    FAQComponent: () => import('@/components/FAQComponent.vue'),
    WalletChooserComponent: () => import('@/components/WalletChooserComponent.vue'),
    ReferralCodeComponent: () => import('@/components/ReferralCodeComponent.vue'),
    NFTSingleComponent: () => import('@/components/NFTSingleComponent.vue'),
    CompetitionFormComponent: () => import('@/components/CompetitionFormComponent.vue'),
  }
})



export default class ModalWrapperComponent extends Vue {
  // prop reactive member variables publically available to the html template
  @Prop({ default: null })
  public innerComponentName: string

  @Prop({ default: null })
  public startingInnerComponentProps: any

  // public reactive member variables available to the html template
  public modalShow: boolean = false
  // @ts-ignore
  public innerComponentProps: any = this.startingInnerComponentProps ? JSON.parse(JSON.stringify(this.startingInnerComponentProps)) : {}
  public dynamicParams: any = null
  public modalTitle: string = null
  public modalSize: string = "md"

  // if caller wants to set a temporary url during popup then restore it on close
  public originalURL: string = null

  // public functions available to the html template

  public onClose(optionalPayload: any) {
    this.payload = optionalPayload
    this.modalShow = false
    // if (this.originalURL) this.$router.replace(this.originalURL).catch(() => {})
    if (this.originalURL) history.replaceState(null, null, this.originalURL)
    
  }

  public setModalTitle(title: string) {
    this.modalTitle = title
    // console.log(`innerComponent setting modalTitle to ${title}`)
  }

  // Parents call this to show modal (use a ref to call child -- https://stackoverflow.com/questions/42632711/how-to-call-function-on-child-component-on-parent-events)
  public show(title: string, size: string, dynamicProps: any): void {
    if (!dynamicProps) dynamicProps = {}

    // special dynamicProp called temporaryURL that callers can use to have the browser show a temporary url during popup which is good for allowing users to copy & paste
    if (dynamicProps.temporaryURL) {
      this.originalURL = this.$router.currentRoute.fullPath
      history.replaceState(null, null, dynamicProps.temporaryURL)
      // this.$router.replace(dynamicProps.temporaryURL).catch(() => {})     // so that user can copy & paste this url 
    }

    dynamicProps.setModalTitle = this.setModalTitle     // in case the innerComponent (e.g., AuctionComponent) needs to change the modal title of the housing ModalWrapperComponent

    this.payload = null
    this.modalTitle = title
    this.modalSize = size
    this.modalShow = true
    this.$nextTick(async () => {
      let x: number = 0
      let innerComponent: any = this.$refs.innerComponent       // can be null because there is a race condition
      while (!innerComponent && x++ < 10) {
        if (this.$store.state.devmode) console.log(`${this.$options.name} show() calling sleep(100)`)     // sleep(1) or sleep(500) doesn't seem to matter -- it's always twice -- so not really a race since it's deterministic.
        await utils.sleep(100)
        innerComponent = this.$refs.innerComponent
      }
      if (innerComponent && innerComponent.onShow) {
        if (this.$store.state.devmode) console.log(`${this.$options.name} show() calling innerComponent.onShow()`)
        await innerComponent.onShow(dynamicProps)
      } else {
        if (this.$store.state.devmode) console.log(`${this.$options.name} show() NOT calling innerComponent.onShow() because innerComponent is ${innerComponent}`)
      }
    })
  }

  // private, non-reactive member variables
  private payload: any = null

  // private functions not available directly to HTML template
  private async mounted() {
    if (this.$store.state.devmode) console.log(`${this.$options.name} mounted()`)
    // console.dir(this.innerComponentProps)
  }
  private async beforeDestroy() { if (this.$store.state.devmode) console.log(`${this.$options.name} beforeDestroy()`) }

  // watched
  @Watch('modalShow')
  private onModalShowChanged(val: boolean, oldVal: boolean) {
    this.modalShow = val;
    this.$emit('changed-modalshow', this.innerComponentName, val, this.payload); // in case parent (e.g. navbar) wants to know we open or closed modal along with inner component payload which is usually just a boolean depicting positive close or negative close
    if (this.$store.state.devmode) console.log(`ModalWrapperComponent.onModalShowChanged() ${this.innerComponentName} ${val}`);
  }
}
