import * as utils from './utils';
import TimeAgo from 'javascript-time-ago';
import moment from 'moment-timezone';

// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en'

// Add locale-specific relative date/time formatting rules.
TimeAgo.addLocale(en)
export const timeAgo = new TimeAgo('en-US')

export interface timeChunks { years: number, months: number, days: number, hours: number, minutes: number, seconds: number, ms: number }
const YEAR_MS: number = 365 * 86400 * 1000
const MONTH_MS: number = YEAR_MS / 12
const DAY_MS: number = 86400 * 1000
const HOUR_MS: number = DAY_MS / 24
const MINUTE_MS: number = HOUR_MS / 60
const SEC_MS: number = 1000

export function createTimeChunksFromMS(ms: number): timeChunks {
  const tc: timeChunks = { years: null, months: null, days: null, hours: null, minutes: null, seconds: null, ms: null }
  let remainingMS: number = ms
  tc.years = Math.floor(remainingMS / YEAR_MS)
  remainingMS %= YEAR_MS
  tc.months = Math.floor(remainingMS / MONTH_MS)
  remainingMS %= MONTH_MS
  tc.days = Math.floor(remainingMS / DAY_MS)
  remainingMS %= DAY_MS
  tc.hours = Math.floor(remainingMS / HOUR_MS)
  remainingMS %= HOUR_MS
  tc.minutes = Math.floor(remainingMS / MINUTE_MS)
  remainingMS %= MINUTE_MS
  tc.seconds = Math.floor(remainingMS / SEC_MS)
  remainingMS %= SEC_MS
  tc.ms = remainingMS
  return tc
}

export function formatDateTimePT(time: Date): string {
  if (!time) return ''
  return moment(time).tz("America/Los_Angeles").format('YYYY-MM-DD HH:mm z')
}

export function prettyTimeAgo(timeDiff: number): string {
  const tc: timeChunks = createTimeChunksFromMS(timeDiff)

  // special nice ones
  if (timeDiff < MINUTE_MS) return 'just now'
  if (timeDiff < HOUR_MS) return tc.minutes === 1 ? `1 min ago` : `${tc.minutes} min ago`
  if (timeDiff < DAY_MS / 3) return tc.hours === 1 ? `1 hour ago` : `${tc.hours} hours ago`
  if (timeDiff < DAY_MS * 2 / 3) return 'today'
  if (timeDiff < DAY_MS * 3 / 2) return 'yesterday'
  if (timeDiff < MONTH_MS) return tc.days === 1 ? `1 day ago` : `${tc.days} days ago`
  if (timeDiff < YEAR_MS) return tc.months === 1 ? `1 month ago` : `${tc.months} months ago`
  if (timeDiff < YEAR_MS * 3) {
    let s: string = (tc.years === 1) ? '1 year ' : `{tc.years} years `
    s += (tc.months === 0) ? 'ago' : ((tc.months === 1) ? '1 month ago' : `${tc.months} months ago`)
    return s
  }
  return `${tc.years} years ago`
}
export function prettyTimeInFuture(timeDiffMs: number): string {
  const tc: timeChunks = createTimeChunksFromMS(timeDiffMs)

  // special nice ones
  if (timeDiffMs < MINUTE_MS) return tc.seconds === 1 ? `in 1 second` : `in ${tc.seconds} seconds`
  if (timeDiffMs < HOUR_MS) return tc.minutes === 1 ? `in 1 minute` : `in ${tc.minutes} minutes`
  if (timeDiffMs < DAY_MS) return tc.hours === 1 ? `in 1 hour` : `in ${tc.hours} hours`
  if (timeDiffMs < MONTH_MS) return tc.days === 1 ? `in 1 day` : `in ${tc.days} days`
  if (timeDiffMs < YEAR_MS) return tc.months === 1 ? `in 1 month` : `in ${tc.months} months`
  if (timeDiffMs < YEAR_MS * 3) {
    let s: string = (tc.years === 1) ? 'in 1 year' : `in {tc.years} years`
    s += (tc.months === 0) ? '' : ((tc.months === 1) ? ' 1 month ' : ` ${tc.months} months`)
    return s
  }
  return `in ${tc.years} years`
}

// given a timeDiff in ms, return a nicely-formatted string
//  e.g. 1d 3h 4m 15s 340ms
export function prettyTimeDiff(timeDiff: number): string {
  let s: string = ''
  let remainingMS: number = timeDiff
  const showMs: boolean = timeDiff < 5 * 1000 // show ms only for short lengths

  const years: number = Math.floor(remainingMS / YEAR_MS)
  remainingMS = remainingMS % YEAR_MS
  if (years > 0 || s.length > 0) s += `${years}y `

  const months: number = Math.floor(remainingMS / MONTH_MS)
  remainingMS = remainingMS % MONTH_MS
  if (months > 0 || s.length > 0) s += `${months}m `

  const days: number = Math.floor(remainingMS / DAY_MS)
  remainingMS = remainingMS % DAY_MS
  if (days > 0 || s.length > 0) s += `${days}d `

  const hours: number = Math.floor(remainingMS / HOUR_MS)
  remainingMS = remainingMS % HOUR_MS
  if (hours > 0 || s.length > 0) s += `${hours}h `

  const minutes: number = Math.floor(remainingMS / MINUTE_MS)
  remainingMS = remainingMS % MINUTE_MS
  if (minutes > 0 || s.length > 0) s += `${minutes}m `

  const seconds: number = Math.floor(remainingMS / SEC_MS)
  remainingMS = remainingMS % SEC_MS
  if (seconds > 0 || s.length > 0) s += `${seconds}s `

  const ms: number = remainingMS
  if (showMs) s += `${ms}ms `

  s = s.substring(0, s.length - 1)  // chop off trailing space

  return s
}

export function prettyYearsMonthsDiff(timeDiff: number): string {
  let s: string = ''
  let remainingMS: number = timeDiff

  const years: number = Math.floor(remainingMS / YEAR_MS)
  remainingMS = remainingMS % (YEAR_MS)
  if (years > 0 || s.length > 0) s += `${years} yrs `

  const months: number = Math.ceil(remainingMS / MONTH_MS)
  if (months > 0) s += `${months} mos `

  return s
}

export function prettyDaysAgo(timeDiff: number): string {
  let s: string = ''
  let remainingMS: number = timeDiff
  const showMs: boolean = timeDiff < 60 * 1000 // show ms only for short lengths

  const days: number = Math.floor(remainingMS / DAY_MS)
  remainingMS = remainingMS % DAY_MS
  if (days === 1) s += `${days} day ago`; else s += `${days} days ago`
  if (days === 1) s = 'yesterday'
  if (days === 0) s = 'today'
  if (days === 0 && timeDiff < 60 * 1000) s = 'just now'  // less than a minute ago

  return s
}

export function getMinColonSeconds(totalSeconds: number) {
  const minutes: number = Math.floor(totalSeconds / 60)
  const seconds: number = Math.floor(totalSeconds % 60)
  return `${minutes.toString()}:${(seconds < 10 ? '0' : '') + seconds.toString() }`
}

(async () => {
  // console.log(prettyTimeAgo(300000))
  // console.log(timeAgo.format(300000))
})()
