import * as utils from './utils';

export async function send(msg: string) {
  const url: string = utils.getURLprefix() + "/api/utils/telegram";
  await utils.makePostCall(url, { command: 'send', msg });
}

export async function sendUrgent(msg: string) {
  const url: string = utils.getURLprefix() + "/api/utils/telegram";
  await utils.makePostCall(url, { command: 'sendUrgent', msg });
}

export async function sendGroup(msg: string) {
  const url: string = utils.getURLprefix() + "/api/utils/telegram";
  await utils.makePostCall(url, { command: 'sendGroup', msg });
}

