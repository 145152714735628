// Vuex is a state management pattern + library for Vue.js applications.
// It serves as a centralized store for all the components in an application, with rules ensuring that the state can only be mutated in a predictable fashion

// At the center of every Vuex application is the store. A "store" is basically a container that holds your application state.
// There are two things that make a Vuex store different from a plain global object:

// Vuex stores are reactive. When Vue components retrieve state from it, they will reactively and efficiently update if the store's state changes.

// You cannot directly mutate the store's state. The only way to change a store's state is by explicitly committing mutations.
// This ensures every state change leaves a track-able record, and enables tooling that helps us better understand our applications

import Vue from 'vue';
import Vuex from 'vuex';
import { NFTUser } from '@/NFTUser';

Vue.use(Vuex);

// The reason we hold user and sessionId in here in addition to localStorage is that if it's just here, then there's no persistence,
//  and if it's just in local storage, then components (e.g. navbar) won't be reactive to them (e.g. upon login/logout).
// Vuex store handles reactivity.

// One thing I dislike about this is that Typescript isn't helping me much here because mutations, actions and getters
//  are called by name. That's just weird.
// I considered using https://github.com/mrcrowl/vuex-typex to make Vuex more typescript-friendly but decided not to for now

// We create the extra steps of GlobalState and globalState just so we can have types on the state fields (makes me feel a little better)
interface GlobalState {
  // ephemeral, but still app-global
  devmode: boolean,
  loading: boolean,
  user: NFTUser,
  sessionId: string,
  topAlertShowing: boolean | number,
  topAlertMessage: string,
  topAlertVariant: string,
  botAlertShowing: boolean | number,
  botAlertMessage: string,
  botAlertVariant: string,
  ebayCategoryId: string,

  // also saved to localStorage for persistence
  preferredFormat: string,
  uniqueClientID: number,
  referrer: string,         // eth address referrer

  // learning
  alex: number,
  buffy: number
}
const globalState: GlobalState = {
  devmode: process.env.VUE_APP_MODE === 'development',
  loading: false,
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
  sessionId: localStorage.getItem('sessionId'),
  topAlertShowing: false,
  topAlertMessage: 'Hello!',
  topAlertVariant: 'success',
  botAlertShowing: false,
  botAlertMessage: 'Hello!',
  botAlertVariant: 'success',
  ebayCategoryId: "-1",

  preferredFormat: localStorage.getItem('preferredFormat'),
  uniqueClientID: Number(localStorage.getItem('ucid')),
  referrer: localStorage.getItem('referrer'),

  alex: 50,
  buffy: 46
}

export default new Vuex.Store({
  // all the field definitions and default values
  state: globalState,

  // synchronous mutation functions
  mutations: {
    setLoading: (state: GlobalState, payload: { loading: boolean }) => { state.loading = payload.loading },
    setUser: (state: GlobalState, payload: { user: NFTUser }) => { state.user = payload.user },
    setSessionId: (state: GlobalState, payload: { sessionId: string }) => { state.sessionId = payload.sessionId },
    setTopAlertShowing: (state: GlobalState, payload: { topAlertShowing: boolean | number }) => { state.topAlertShowing = payload.topAlertShowing },
    setTopAlertMessage: (state: GlobalState, payload: { topAlertMessage: string }) => { state.topAlertMessage = payload.topAlertMessage },
    setTopAlertVariant: (state: GlobalState, payload: { topAlertVariant: string }) => { state.topAlertVariant = payload.topAlertVariant },
    setBotAlertShowing: (state: GlobalState, payload: { botAlertShowing: boolean | number }) => { state.botAlertShowing = payload.botAlertShowing },
    setBotAlertMessage: (state: GlobalState, payload: { botAlertMessage: string }) => { state.botAlertMessage = payload.botAlertMessage },
    setBotAlertVariant: (state: GlobalState, payload: { botAlertVariant: string }) => { state.botAlertVariant = payload.botAlertVariant },

    setPreferredFormat: (state: GlobalState, payload: { preferredFormat: string }) => { state.preferredFormat = payload.preferredFormat; localStorage.setItem('preferredFormat', payload.preferredFormat) },
    setUniqueClientID: (state: GlobalState, payload: { uniqueClientID: number }) => { state.uniqueClientID = payload.uniqueClientID; localStorage.setItem('ucid', payload.uniqueClientID.toString()) },
    setReferrer: (state: GlobalState, payload: { referrer: string }) => { state.referrer = payload.referrer; localStorage.setItem('referrer', payload.referrer) },

    makeAlexYounger: (state: GlobalState) => { state.alex-- },
    makeAlexOlder: (state: GlobalState) => { state.alex++ }
  },

  // asynchronous mutation functions
  actions: {
    makeAlexYounger: async (context) => { context.state.alex-- },
    makeAlexOlder: async (context) => { context.state.alex++ }
  },

  // like computed properties, but for Vuex store
  getters: {
    isAuthenticated: (state: GlobalState) => state.user != null && state.sessionId != null,
    userObject: (state: GlobalState) => state.user ? new NFTUser(state.user) : null,
    userId: (state: GlobalState) => state.user ? new NFTUser(state.user).getIdAsString() : null,
    username: (state: GlobalState) => state.user ? new NFTUser(state.user).username : null
  }
});
