import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home.vue';
import Mint2 from '@/views/Mint2.vue';
import Team from '@/views/Team.vue';
import Roadmap from '@/views/Roadmap2.vue';
import FAQ from '@/views/FAQ.vue';
import Account from '@/views/Account.vue';
import Competitions from '@/views/Competitions.vue';
import * as utils from '../utils'

Vue.use(Router);

// Nov 4, 2021
// Different set of routes based on domain name; this allows us to have a single Vue app host
//  a bunch of different NFT project sites.
const host: string = window.location.host;
let router: Router

// For each route, you assign a component that gets rendered inside <router-view></router-view> in App.vue, just below the navbar (see App.vue).
// I'm not sure why the Vue CLI created two different directories, views and components, but I think they meant for components that are full views
// to be put in views, and just modular components to be put in components. Here, we can use either components in the views dir (like Callback and About),
// or in the components dir. Doesn't really seem to matter, as components can always house other components.
// Yes, seems like it's really just convention - https://stackoverflow.com/questions/50865828/what-is-the-difference-between-the-views-and-components-folders-in-a-vue-project
// And, according to this, views directory didn't use to exist - https://blog.pusher.com/new-vue-cli-simplifies-development/
// BTW, you can also have named router views - https://router.vuejs.org/guide/essentials/named-views.html#nested-named-views
if (host.includes("pieland") || host.includes("localhost")) {
  if (utils.redirectHomePage()) {
    // not only redirect home page, but let's wire off mint, team, faq, and roadmap too
    router = new Router({
      mode: 'history',
      base: process.env.BASE_URL,
      routes: [
        {
          path: '/',
          name: 'home',
          component: Home,
        },
        {
          path: '/mint',
          name: 'mint',
          component: Home,
        },
        {
          path: '/mypies',
          name: 'account',
          component: Account,
        },
        {
          path: '/team',
          name: 'team',
          component: Home,
        },
        {
          path: '/faq',
          name: 'faq',
          component: Home,
        },
        {
          path: '/roadmap',
          name: 'roadmap',
          component: Home,
        },
        {
          path: '/competitions',
          name: 'competitions',
          component: Competitions,
        }
      ],
    });
  } else {
    router = new Router({
      mode: 'history',
      base: process.env.BASE_URL,
      routes: [
        {
          path: '/',
          name: 'home',
          component: Home,
        },
        {
          path: '/mint',
          name: 'mint',
          component: Mint2,
        },
        {
          path: '/mypies',
          name: 'account',
          component: Account,
        },
        {
          path: '/team',
          name: 'team',
          component: Team,
        },
        {
          path: '/faq',
          name: 'faq',
          component: FAQ,
        },
        {
          path: '/roadmap',
          name: 'roadmap',
          component: Roadmap,
        },
        {
          path: '/competitions',
          name: 'competitions',
          component: Competitions,
        }
      ],
    });
  }
}

router.addRoutes([
  {
    path: '/confirm/:code',
    name: 'confirm',
    component: () => import(/* webpackChunkName: "test" */ '../views/Confirm.vue'),
  },
  {
    path: '/m/:code',
    name: 'm',
    component: () => import(/* webpackChunkName: "test" */ '../views/MagicURL.vue'),
  },
  {
    path: '/unsubscribe/:code',
    name: 'unsubscribe',
    component: () => import(/* webpackChunkName: "test" */ '../views/Unsubscribe.vue'),
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () => import(/* webpackChunkName: "test" */ '../views/PrivacyPolicy.vue'),
  },
])

// very basic "setup" of a global guard
router.beforeEach((to, from, next) => {
  // if the to route doesn't match any defined routes, redirect to /
  if (to.matched.length === 0) {
    console.log(`router.beforeEach() found no matches for ${JSON.stringify(to)}`)
    next('/')
  }

  if (to.name && to.name.includes('callback')) { // check if "to"-route is "callback" and allow access
    next();
  } else {
    next();
  }
});

export default router;

