import { render, staticRenderFns } from "./SocialBarComponent.vue?vue&type=template&id=32c604ba&scoped=true&"
import script from "./SocialBarComponent.vue?vue&type=script&lang=ts&"
export * from "./SocialBarComponent.vue?vue&type=script&lang=ts&"
import style0 from "./SocialBarComponent.vue?vue&type=style&index=0&id=32c604ba&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32c604ba",
  null
  
)

export default component.exports