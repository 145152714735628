

































































































































import { Component, Vue } from 'vue-property-decorator';
import { NFTUser } from '@/NFTUser'
import * as utils from '@/utils';
import * as utils_auth from '@/utils_auth';
import * as utils_tracker from '@/utils_tracker';
import ModalWrapperComponent from '@/components/ModalWrapperComponent.vue';             // @ is an alias to /src
import SocialBarComponent from '@/components/SocialBarComponent.vue';             // @ is an alias to /src

@Component({
  name: 'Home',
  components: { ModalWrapperComponent, SocialBarComponent }
})

export default class Home extends Vue {
  // prop reactive member variables publically available to the html template

  // public reactive member variables available to the html template  

  public get loading(): boolean { return this.$store.state.loading }
  public get isAuthenticated(): boolean { return this.$store.getters.isAuthenticated }
  public get user(): NFTUser { return this.$store.getters.userObject }

  public showShip: boolean = false
  public showLogo: boolean = false

  // public get imageSource(): string { return `/images/home_whole_bg_1920x3234.jpg` }

  public userClickedJoin() {
    window.location.href = "https://discord.gg/pieland";
  }

  public async userClickedMint() {
    try { await this.$router.push({ name: 'mint'}) } catch (Error) {}
  }  

  public userClickedOpenSea() {
    window.location.href = "https://opensea.io/collection/pieland";
  }

  public showBlankPage(): boolean {
    return utils.redirectHomePage()
  }

  // private, non-reactive member variables

  // private functions not available directly to HTML template
  private async mounted() {
    if (this.$store.state.devmode) console.log(`${this.$options.name} mounted()`)
    utils_tracker.page(this.$options.name)

    // March 10, 2024
    if (utils.redirectHomePage()) {
      window.location.href = "https://www.pielandworld.com/";
      return
    }

    setTimeout(() => { this.showShip = true; this.showLogo = true; }, 50)

    // ethereum address referrer from ref query parameter in query string
    if (this.$route.query.ref) {
      const referrer: string = this.$route.query.ref as string
      this.$store.commit('setReferrer', { referrer });
    }  
  }

  private async beforeDestroy() { 
    if (this.$store.state.devmode) console.log(`${this.$options.name} beforeDestroy()`)
  }


}

