















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { NFTUser } from '@/NFTUser'
import * as utils from '../utils';
import * as utils_auth from '../utils_auth';
import ModalWrapperComponent from '@/components/ModalWrapperComponent.vue';           // @ is an alias to /src
import { globalUC } from '../UserCache';
import * as utils_ether from '../utils_ether';
import { ethers } from "ethers";

interface Link {
  name: string
  path: string
}

@Component({
  name: 'NavbarComponent',
  components: { 
    ModalWrapperComponent: () => import('@/components/ModalWrapperComponent.vue'),
    }
})
export default class NavbarComponent extends Vue {
  // prop reactive member variables publically available to the html template
  public links: Link[] = []
  public SHOW_USER: boolean = true

  // public functions available to the html template
  public get loading(): boolean { return this.$store.state.loading }
  public get user(): NFTUser { return this.$store.getters.userObject }
  public get userId(): string { return this.$store.getters.userId }
  public get siteName(): string { return "PIELAND" }
  public get siteDomain(): string { return utils.getCurrentHostname() }
  public get username(): string { return this.user.username || this.user.ENSname || utils_ether.niceAddress(this.user.address) }
  public get balance(): string { return this.user && this.user.lastBalance ? this.user.lastBalance.toFixed(4) : '0.0000' }

  public getTagline(): string {
    const taglines: string[] = [
      "We don't mint, we bake.",
      "No time to Pie.",
      "Crust no one.",
      "You're the apple of my Pie.",
      "A Pie for a Pie makes the whole world Pie.",
      "Live and let Pie.",
      "Beauty is in the Pie of the beholder.",
      "Piece and love.",
      "It's a good day to Pie.",
      "I didn't choose the crust life, the crust life chose me.",
      "Carpie Diem.",
      "In Pie We Crust.",
      "We All Gonna Make Pie.",
      "Pies Gonna Make It."
]
    return taglines[Math.floor(Math.random() * taglines.length)]
  }

  public isTouchDevice(): boolean { return utils.isTouchDevice() }

  public async userChoseSignOut() { 
    await utils_auth.logout()
  }

  public async userChoseConnect() {
    // (this.$refs.WalletChooserComponent as ModalWrapperComponent).show('Choose Wallet', 'sm', null);
    // return

    // skip modal because walletconnect doesn't work yet
    const ethereum = await utils_ether.getEthereum()
    if (!ethereum) {
      // TODO: detect browser to customize error message
      utils.toast(this, 'Wallet Not Detected', 'Install the MetaMask browser extension before connecting.', 5000, 'warning')
      return
    }
    await utils_ether.connect()

  }

  public async userChoseWallet(innerComponentName: string, showing: boolean, wallet: string) {
    if (wallet) {
      const ethereum = await utils_ether.getEthereum()
      if (!ethereum) {
        // TODO: detect browser to customize error message
        utils.toast(this, 'Wallet Not Detected', 'Install the MetaMask browser extension before connecting.', 5000, 'warning')
        return
      }
      await utils_ether.connect()
    }
  }

  // private, non-reactive member variables
  private tab: string = null

  @Watch('$route.path')
  private async pathChanged(): Promise<void> {
    this.doTabs()
    this.tab = this.$route.path.split('/')[1]

    // utils.setCurrentTab(this.tab)
    if (this.$store.state.devmode) console.log(`navbar.pathChanged() ${this.$route.path} => this.tab: ${this.tab}`)
  }

  // March 11, 2024: we are now redirecting to pielandworld so let's not show navbar on blank home page
  public dontShowNav(): boolean {
    return this.$route.path === '/' && utils.redirectHomePage()
  }

  private async mounted(): Promise<void> {
    if (this.$store.state.devmode) console.log(`${this.$options.name} mounted()`)

    this.doTabs();

    // the user cache may have a later version of our user than our store (e.g. profile pic), so update it
    if (this.userId) {
      const userFromCache: NFTUser = await globalUC.get(this.userId, true)
      if (userFromCache) utils_auth.saveAuthStuffToStoreAndLocalStorage(userFromCache, null)
    }

    // handle events sent from utils_ether
    utils_ether.event.$on(utils_ether.MSGS.NOT_CONNECTED, () => {
      utils.toast(this, 'Error', utils_ether.MSGS.NOT_CONNECTED)
      utils_auth.clearAuthStuffFromStoreAndLocalStorage()
    })
    utils_ether.event.$on(utils_ether.MSGS.NOT_READY, () => {
      utils.toast(this, 'Not Connected', 'Wallet did not connect.', 2000, 'warning')
      utils_auth.clearAuthStuffFromStoreAndLocalStorage()
    })
    utils_ether.event.$on(utils_ether.MSGS.NO_WALLET, () => {
      // utils.toast(this, 'Error', utils_ether.MSGS.NO_WALLET)
      utils_auth.clearAuthStuffFromStoreAndLocalStorage()
    })
    utils_ether.event.$on(utils_ether.MSGS.ACCOUNT_CHANGED, async () => {
      if (this.$store.state.devmode) console.log('utils_ether.MSGS.ACCOUNT_CHANGED')
      const address: string = utils_ether.getCurrentAccount()
      const user: NFTUser = new NFTUser(null)
      user.address = address
      user.lastChainId = utils_ether.getChainId()
      const balance = await utils_ether.getProvider().getBalance(address)
      const balanceAsNumber: number = Number(ethers.utils.formatEther(balance))
      user.lastBalance = balanceAsNumber
      
      if (await utils_ether.hasEns()) user.ENSname = await utils_ether.getProvider().lookupAddress(address)

      // toast only if it changed
      if (!this.user || this.user.address !== user.address || this.user.username !== user.username || this.user.ENSname !== user.ENSname || this.user.lastChainId !== user.lastChainId) {
        utils.toast(this, 'Wallet Connected', `${utils_ether.niceAddress(address)} connected to the ${utils_ether.getNetName()}.`, null, 'success')
      }

      utils_auth.saveAuthStuffToStoreAndLocalStorage(user, null)


})
 

}


  private async beforeDestroy() { if (this.$store.state.devmode) console.log(`${this.$options.name} beforeDestroy()`) }

  // private functions not available directly to HTML template
  private doTabs(): void {
    this.links = []
    this.links.push({ name: 'Home', path: '/' })
    // this.links.push({ name: 'Roadmap', path: '/roadmap' })
    // this.links.push({ name: 'FAQ', path: '/faq' })
    // this.links.push({ name: 'Team', path: '/team' })
    // this.links.push({ name: 'About', path: '/about' })
    // this.links.push({ name: 'Blog', path: '/blog' })
    this.links.push({ name: 'Adventure', path: '/adventure' })
    this.links.push({ name: 'Mint', path: '/mint' })
    // this.links.push({ name: 'My Pies', path: '/account' })
  }

}
