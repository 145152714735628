






// https://bootstrap-vue.js.org/docs/components/alert
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'AlertComponentTop',
  components: {}
})
export default class AlertComponentTop extends Vue {
  // prop reactive member variables publically available to the html template

  // public reactive member variables available to the html template
  public get topAlertShowing(): boolean | number { return this.$store.state.topAlertShowing }
  public set topAlertShowing(showing: boolean | number) { this.$store.commit("setTopAlertShowing", { topAlertShowing: showing }); }
  public get topAlertMessage(): string { return this.$store.state.topAlertMessage }
  public get topAlertVariant(): string { return this.$store.state.topAlertVariant }

  // public functions available to the html template

  // private, non-reactive member variables

  // private functions not available directly to HTML template
  private async mounted() { if (this.$store.state.devmode) console.log(`${this.$options.name} mounted()`) }
  private async beforeDestroy() { if (this.$store.state.devmode) console.log(`${this.$options.name} beforeDestroy()`) }

}
