

























































































































































































import { Component, Vue } from 'vue-property-decorator';
import { NFTUser } from '@/NFTUser'
import * as utils from '@/utils';
import * as utils_auth from '@/utils_auth';
import * as utils_tracker from '@/utils_tracker';
import ModalWrapperComponent from '@/components/ModalWrapperComponent.vue';             // @ is an alias to /src
import * as utils_ether from '../utils_ether';
import * as utils_ethers from '../utils_ethers';
import * as utils_contract from '../utils_contract';
import { ethers, BigNumber } from "ethers";

enum saleTypes { PreSale = 1, MainSale = 2 }
type contractType = 'Base' | 'Ticket' | 'WithPreSaleBasedOnTickets' | 'WithPreSaleBasedOnWhitelist' | 'WithPreSaleBasedOnOwnershipOfAnotherNFT'

@Component({
  name: 'Mint2',
  components: { ModalWrapperComponent }
})

export default class Mint2 extends Vue {
  // prop reactive member variables publically available to the html template

  // public reactive member variables available to the html template
  public get loading(): boolean { return this.$store.state.loading }
  public get isAuthenticated(): boolean { return this.$store.getters.isAuthenticated }
  public get user(): NFTUser { return this.$store.getters.userObject }
  public get buttonText(): string { 
    if (this.mintingInProgress) return "Minting…"
    if (this.soldOut && this.totalSupply < this.MAX_TOTAL_SUPPLY || this.totalBalance < this.MAX_BALANCE_TOTAL) return 'Pre-Sale Sold Out'
    if (this.soldOut) return 'Sold Out'
    if (this.mintable) return `Mint ${this.numToMint}`
    if (this.preSaleOngoing || this.mainSaleOngoing) return 'Cannot Mint'
    return 'Minting not Active'
  }
  public get buttonVariant(): string { 
    if (this.mintingInProgress) return "outline-warning"
    if (this.soldOut) return 'danger'
    if (this.mintable) return 'success'
    return "dark"
  }
  public getOwnerFromContract(): string { return utils_contract.owner }
  public getNameOfSaleFromContract(): string { return utils_contract.nameOfSale }
  public getNameOfTicketOrPassFromContract(): string { return utils_contract.nameOfTicketOrPass }

  // constants to manually change
  public PRESALE_DROP_DATE: Date = new Date('2022-02-10T17:00:00.000Z')       // can be null
  public MAINSALE_DROP_DATE: Date = new Date('2022-02-10T23:00:00.000Z')      // cannot be null
  public REFERRAL_LAUNCH_DATE: Date = new Date('2022-02-19T21:00:00.000Z')      // cannot be null

  // deduced
  public saleType: saleTypes = null 
  public mintable: boolean = false
  public soldOut: boolean = false

  // fetched via provider
  public currentGasPrice: number = null                  // in gwei

  // fetched from smart contract via provider
  public contractType: contractType = null                     

  public MAX_TOTAL_SUPPLY: number = null
  public MAX_PRE_SALE: number = null
  
  public MAX_BALANCE_TOTAL: number = null
  public MAX_BALANCE_PER_TICKET: number = null

  public MAX_PER_TX: number = null
  public MAX_TOTAL_PER_WALLET: number = null
  public MINT_PRICE: number = null              // converted to eth

  public preSaleOngoing: boolean = null
  public mainSaleOngoing: boolean = null
  public totalSupply: number = null
  public totalBalance: number = null

  // public numReserved: number = null          // got rid of to save space in contract
  // public numGivenAway: number = null         // got rid of to save space in contract

  public numMintedInPreSale: number = null

  public preSaleMintedAmountForUser: number = null
  public mainSaleMintedAmountForUser: number = null

  public isWhiteListedForPreSale: boolean = null
  public totalBalanceForUser: number = null
  public ownsPassForPreSale: boolean = null

  // public msUntilDrop: number = null

  // status
  public fetched: boolean = false

  // UI
  public numToMint: number = 1
  public stampsToMint: number = 1   // TODO
  public ticketId: number = 0       // TODO
  public mintingInProgress: boolean = false
  public showSlices: boolean[] = [ true, true, true, true, true, true, true, true, true]


  // Admin stuff
  public adminMode(): boolean {
    if (!this.$route.query.admin || (this.$route.query.admin as string) !== 'true') return false
    if (utils_contract.owner && utils_ether.getCurrentAccount() && utils_contract.owner.toLocaleLowerCase() === utils_ether.getCurrentAccount().toLocaleLowerCase()) return true
    return false
  }
  public whitelistAddresses: string = null
  public giveAwayInput: string = null

  // public functions available to the html template
  public async userChoseConnect() {
    const ethereum = (window as any).ethereum
    if (!ethereum) {
      // TODO: detect browser to customize error message
      utils.toast(this, 'Wallet Not Detected', 'Install the MetaMask browser extension before connecting.', 5000, 'warning')
      return
    }
    await utils_ether.connect()
  }

  public showReferralButton() {
    return new Date() > this.REFERRAL_LAUNCH_DATE && !this.soldOut && this.MAX_TOTAL_SUPPLY;
  }

  public userChoseReferralCode() {
    (this.$refs.ReferralCodeComponent as any).show(`Referral Code Program`, 'md', null)
  }

  public userChoseMinus() {
    this.numToMint--
    if (this.numToMint <= 0) this.numToMint = 1
  }
  public userChosePlus() {
    this.numToMint++
    if (this.numToMint >= this.MAX_PER_TX) this.numToMint = this.MAX_PER_TX
  }

  public async userChoseMint() {
    await this.doMint()

    // const dynamicProps: any = {};
    // (this.$refs.MintComponent as ModalWrapperComponent).show('Mint', 'md', dynamicProps);
  }
  public onMintChangedModalShow(componentName: string, showing: boolean, payload: any) {
    if (!showing) {
    }
  }

  public pastMainSaleDropDate(): boolean {
    if (!this.MAINSALE_DROP_DATE) return false
    return new Date() > this.MAINSALE_DROP_DATE
  }

  public getDeadlineString(): string {
    const now: Date = new Date()
    if (this.PRESALE_DROP_DATE && now < this.PRESALE_DROP_DATE) return this.PRESALE_DROP_DATE.toLocaleString('en-US')     // 'en-US' very important
    if (this.MAINSALE_DROP_DATE) return this.MAINSALE_DROP_DATE.toLocaleString('en-US')                                   // 'en-US' very important
    return null
  }

  public async flipPreSaleState() {
    const provider = utils_ether.getProvider()
    const signer = provider.getSigner()
    const contract: ethers.Contract = await utils_contract.getMainContract(signer)
    await contract.flipPreSaleState()
  }
  public async flipMainSaleState() {
    const provider = utils_ether.getProvider()
    const signer = provider.getSigner()
    const contract: ethers.Contract = await utils_contract.getMainContract(signer)
    await contract.flipMainSaleState()
  }
  public async removeFromWhitelist() {
    if (!this.whitelistAddresses || this.whitelistAddresses.length === 0) return
    const addresses: string[] = this.whitelistAddresses.split(',').map(a => a.trim())
    const provider = utils_ether.getProvider()
    const signer = provider.getSigner()
    const contract: ethers.Contract = await utils_contract.getMainContract(signer)
    await contract.removeAddressesFromWhitelist(addresses)
  }
  public async addToWhitelist() {
    if (!this.whitelistAddresses || this.whitelistAddresses.length === 0) return
    const addresses: string[] = this.whitelistAddresses.includes(',') ? this.whitelistAddresses.split(',').map(a => a.trim()) : this.whitelistAddresses.split('\n').map(a => a.trim())
    const provider = utils_ether.getProvider()
    const signer = provider.getSigner()
    const contract: ethers.Contract = await utils_contract.getMainContract(signer)
    await contract.addAddressesToWhitelist(addresses)
  }
  // giveAway == mintPreOrder
  public async giveAway() {
    if (!this.giveAwayInput || this.giveAwayInput.length === 0) return
    const addressAndAmounts: string[] = this.giveAwayInput.includes(',') ? this.giveAwayInput.split(',').map(a => a.trim()) : this.giveAwayInput.split('\n').map(a => a.trim())
    for (const addressAndAmount of addressAndAmounts) {
      const address: string = addressAndAmount.split('/')[0]
      const amount: number = Number(addressAndAmount.split('/')[1])
      const provider = utils_ether.getProvider()
      const signer = provider.getSigner()
      const contract: ethers.Contract = await utils_contract.getMainContract(signer)
      if (amount <= 0 || !amount) {
        utils.toast(this, 'Error', `You tried to give away ${amount} (strange) to ${address}.`)
        return
      }      
      if (amount > 40) {
        utils.toast(this, 'Error', `You tried to give away ${amount} (too many) to ${address}.`)
        return
      }
      await contract.mintPreOrder(address, amount)
      utils.toast(this, 'mintPreOrder', `Minted ${amount} to ${address}.`)
    }
  }

  // private, non-reactive member variables
  private timer: NodeJS.Timeout = null
  private timer2: NodeJS.Timeout = null
  private failedLastTime: boolean = false
  private numAutoFetchFailuresInARow: number = 0
  private alreadySubscribedToMintEvents: boolean = false
  private contract: ethers.Contract = null                  // the contract for minting during this drop
  private contractForPreSaleTicket: ethers.Contract = null  // if contract.contractType === WithPreSaleBasedOnTickets, we'll need this too 
  private mintEventsSoFar: number[] = []                    // so that we don't show duplicate mint events, we keep an array of mint events' totalSupply numbers

  // private functions not available directly to HTML template
  private async mounted() {
    if (this.$store.state.devmode) console.log(`${this.$options.name} mounted()`)

    // ethereum address referrer from ref query parameter in query string
    if (this.$route.query.ref) {
      const referrer: string = this.$route.query.ref as string
      this.$store.commit('setReferrer', { referrer });
    }  

    utils_tracker.page(this.$options.name)

    /*
    setTimeout(async () => { for (let i=0; i<9; i++) {
      this.showSlices[i] = true;
      // await utils.sleep(500)
    }}, 100)
    */

    this.checkMintStats()

    // this.timer2 = setInterval(() => {
    //   this.computeTimeUntilDrop()
    // }, 1000)

  }

  private async beforeDestroy() { 
    if (this.$store.state.devmode) console.log(`${this.$options.name} beforeDestroy()`)

    if (this.timer) clearTimeout(this.timer)
    this.timer = null
    if (this.timer2) clearTimeout(this.timer2)
    this.timer2 = null
  }

  /*
  private computeTimeUntilDrop() {
    const now: Date = new Date()
    this.msUntilDrop = 0
    if (this.PRESALE_DROP_DATE && now < this.PRESALE_DROP_DATE) {
      this.msUntilDrop = this.PRESALE_DROP_DATE.getTime() - new Date().getTime()
    } else {
      if (this.MAINSALE_DROP_DATE) {
        this.msUntilDrop = this.MAINSALE_DROP_DATE.getTime() - new Date().getTime()
      }
    }
    if (this.msUntilDrop < 0) this.msUntilDrop = 0
  }
  */
 

  private async doMint() {
    // check per tx maximums
    if (this.saleType === saleTypes.PreSale && this.numToMint > this.MAX_PER_TX && this.MAX_PER_TX > 0) {
      utils.toast(this, 'Error', `You cannot mint more than ${this.MAX_PER_TX} per transaction during the pre-sale.`)
      return
    }
    if (this.saleType === saleTypes.MainSale && this.numToMint > this.MAX_PER_TX && this.MAX_PER_TX > 0) {
      utils.toast(this, 'Error', `You cannot mint more than ${this.MAX_PER_TX} per transaction.`)
      return
    }

    // check per wallet maximums
    if (this.saleType === saleTypes.PreSale && this.numToMint + this.preSaleMintedAmountForUser > this.MAX_TOTAL_PER_WALLET && this.MAX_TOTAL_PER_WALLET > 0) {
      utils.toast(this, 'Error', `You cannot mint more than ${this.MAX_TOTAL_PER_WALLET} total during the pre-sale.`)
      return
    }
    if (this.saleType === saleTypes.MainSale && this.numToMint + this.mainSaleMintedAmountForUser > this.MAX_TOTAL_PER_WALLET && this.MAX_TOTAL_PER_WALLET > 0) {
      utils.toast(this, 'Error', `You cannot mint more than ${this.MAX_TOTAL_PER_WALLET} total.`)
      return
    }

    // check per tx maximums for ticket sales
    if (this.contractType === 'Ticket') {
      if (this.stampsToMint  > this.MAX_BALANCE_PER_TICKET) {
        utils.toast(this, 'Error', `You cannot mint more than ${this.MAX_BALANCE_PER_TICKET} stamps per ticket.`)
        return
      }
    }

    // do it
    const provider = utils_ether.getProvider()
    const signer = provider.getSigner()
    const contract: ethers.Contract = await utils_contract.getMainContract(signer)
    const num: number = this.contractType !== 'Ticket' ? this.numToMint : this.stampsToMint
    console.log(Math.round(this.MINT_PRICE * num * 1e9))
    let value: BigNumber = BigNumber.from(Math.round(this.MINT_PRICE * num * 1e9))      // 1e9 gets us half way there so we don't underflow nor overflow
    value = value.mul(1e9)
    // From experimentation, gasLimit in overrides is indeed respected if I choose to pass one. But here's the thing -- in testing, metamask looks like
    //  it does a quick gasEstimate call first, then puts in the real tx. So, I shouldn't need to pass anything here. But I am a little worried
    //  that when things are conjested, MetaMask will do something really dumb like populate with 0 or 28500000. Might have to do a test on mainnet
    //  during a busy time - yes, don't actually have to follow through with the tx.
    const overrides: ethers.PayableOverrides = {
      // TODO: different gasLimit based on which of the four contracts
      // gasLimit: 50000 + num * 150000,    // pretty safe, based on empirical #s on rinkeby (btw, they do vary from try to try) - should be cheaper for ticket sales
      value
    }
    try {
      this.mintingInProgress = true
      if (this.contractType !== 'Ticket') {
        const response = this.saleType === saleTypes.PreSale ? await contract.mintPreSale(num, overrides) : await contract.mint(num, overrides) 
        if (this.$store.state.devmode) console.log('Reponse to contract.mint() is:')
        if (this.$store.state.devmode) console.log(response)
      } else {
        const response = this.saleType === saleTypes.PreSale ? await contract.mintPreSale(this.ticketId, num, overrides) : await contract.mint(num, overrides) 
        if (this.$store.state.devmode) console.log('Reponse to contract.mint() is:')
        if (this.$store.state.devmode) console.log(response)
      }
    } catch (e) {
        const errMsg: string = e.message
        if (this.$store.state.devmode) console.log('Exception thrown for contract.mint():')
        if (this.$store.state.devmode) console.log(e)
        if (errMsg) {
          if (errMsg.includes("User denied")) {
            console.log("Minting canceled by user.")
          } else {
            utils.toast(this, 'Error', errMsg, 5000)
          }
          this.mintingInProgress = false
        }
    }

  }

  private contractSupportsPreSale(): boolean {
    if (this.contractType === 'WithPreSaleBasedOnTickets') return true
    if (this.contractType === 'WithPreSaleBasedOnWhitelist') return true
    if (this.contractType === 'WithPreSaleBasedOnOwnershipOfAnotherNFT') return true
    return false
  }

  private contractIsForTicket(): boolean {
    if (this.contractType === 'Ticket') return true
    return false
  }

  private contractIsForPresaleBasedOnWhitelist(): boolean {
    if (this.contractType === 'WithPreSaleBasedOnWhitelist') return true
    return false
  }  

  private contractIsForPresaleBasedOnTicket(): boolean {
    if (this.contractType === 'WithPreSaleBasedOnTickets') return true
    return false
  }

  private contractIsForPresaleBasedOnAnotherNFT(): boolean {
    if (this.contractType === 'WithPreSaleBasedOnOwnershipOfAnotherNFT') return true
    return false
  }  

  private async checkMintStats() {
    if (utils_ether.ready()) {
      if (this.$store.state.devmode) console.log('checkMintStats()...')

      try {
        const provider = utils_ether.getProvider()

        const gasPriceInWei = await provider.getGasPrice()
        if (gasPriceInWei) this.currentGasPrice = Math.round(Number(ethers.utils.formatUnits(gasPriceInWei, 'gwei')))

        let contract: ethers.Contract = this.contract
        if (!contract) {
          this.contract = await utils_contract.getMainContract(provider)
          contract = this.contract
        }
        if (this.contractType == null) this.contractType = await contract.contractType()

        // these don't change during a drop, so read them only once
        if (this.MAX_TOTAL_SUPPLY == null) this.MAX_TOTAL_SUPPLY = Number(await contract.MAX_TOTAL_SUPPLY())
        if (this.MAX_PRE_SALE == null && this.contractType === 'WithPreSaleBasedOnWhitelist') this.MAX_PRE_SALE = Number(await contract.MAX_PRE_SALE())

        if (this.MAX_BALANCE_TOTAL == null && this.contractIsForTicket()) this.MAX_BALANCE_TOTAL = Number(await contract.MAX_BALANCE_TOTAL())
        if (this.MAX_BALANCE_PER_TICKET == null && this.contractIsForTicket()) this.MAX_BALANCE_PER_TICKET = Number(await contract.MAX_BALANCE_PER_TICKET())

        if (this.MAX_PER_TX == null) this.MAX_PER_TX = Number(await contract.MAX_PER_TX())
        if (this.MAX_TOTAL_PER_WALLET == null) this.MAX_TOTAL_PER_WALLET = Number(await contract.MAX_TOTAL_PER_WALLET())
        if (this.MINT_PRICE == null) this.MINT_PRICE = Number(ethers.utils.formatEther(await contract.MINT_PRICE()))

        // whereas these change
        if (this.contractSupportsPreSale()) this.preSaleOngoing = await contract.preSaleOngoing()
        this.mainSaleOngoing = await contract.mainSaleOngoing()
        this.totalSupply = await contract.totalSupply()
        if (this.contractIsForTicket()) this.totalBalance = await contract.totalBalance()

        // Got rid of these from contract to save bytes
        // this.numReserved = await contract.numReserved()
        // this.numGivenAway = await contract.numGivenAway()

        if (this.contractSupportsPreSale()) this.numMintedInPreSale = await contract.numMintedInPreSale()

        if (this.contractIsForPresaleBasedOnWhitelist()) this.isWhiteListedForPreSale = await contract.preSaleWhitelist(utils_ether.getCurrentAccount())
        if (this.contractSupportsPreSale()) this.preSaleMintedAmountForUser = Number(await contract.preSaleMintedAmounts(utils_ether.getCurrentAccount()))
        this.mainSaleMintedAmountForUser = Number(await contract.mainSaleMintedAmounts(utils_ether.getCurrentAccount()))

        // if we're doing a sale of stamped tickets, let's calculate totalBalanceForUser that the user has bought so far
        if (this.contractIsForTicket()) {
          this.totalBalanceForUser = 0
          const tokenIds: number[] = await contract.getTokensOfOwner(utils_ether.getCurrentAccount())
          for (const tokenId of tokenIds) this.totalBalanceForUser += Number(await contract.balanceOfTicket(tokenId))
        }

        // deduced fields
        if (!this.preSaleOngoing && !this.mainSaleOngoing) this.saleType = this.PRESALE_DROP_DATE && new Date() < this.PRESALE_DROP_DATE ? saleTypes.PreSale : saleTypes.MainSale
        if (this.preSaleOngoing && !this.mainSaleOngoing) this.saleType = saleTypes.PreSale
        if (this.mainSaleOngoing) this.saleType = saleTypes.MainSale

        this.soldOut = (this.MAX_TOTAL_SUPPLY && this.totalSupply >= this.MAX_TOTAL_SUPPLY) || (this.saleType === saleTypes.PreSale && (this.MAX_PRE_SALE && this.numMintedInPreSale >= this.MAX_PRE_SALE)) || (this.MAX_BALANCE_TOTAL && this.totalBalance >= this.MAX_BALANCE_TOTAL)
        this.mintable = !this.soldOut && (this.preSaleOngoing || this.mainSaleOngoing)
        if (this.mintable && this.saleType === saleTypes.PreSale && this.contractType === 'WithPreSaleBasedOnWhitelist' && !this.isWhiteListedForPreSale) this.mintable = false
        if (this.mintable && this.saleType === saleTypes.PreSale && this.contractType === 'WithPreSaleBasedOnTickets' && this.totalBalanceForUser <= 0) this.mintable = false
        if (this.mintable && this.saleType === saleTypes.PreSale && this.contractType === 'WithPreSaleBasedOnOwnershipOfAnotherNFT' && !this.ownsPassForPreSale) this.mintable = false

        if (this.$store.state.devmode && false) {
          console.log(`MAX_TOTAL_SUPPLY: ${this.MAX_TOTAL_SUPPLY}`)
          console.log(`totalSupply: ${this.totalSupply}`)
          console.log(`MAX_PRE_SALE: ${this.MAX_PRE_SALE}`)
          console.log(`numMintedInPreSale: ${this.numMintedInPreSale}`)
          console.log(`soldOut: ${this.soldOut}`)
          console.log(`preSaleOngoing: ${this.preSaleOngoing}`)
          console.log(`mainSaleOngoing: ${this.mainSaleOngoing}`)
          console.log(`mainSaleOngoing: ${typeof this.mainSaleOngoing}`)
        }

        // if we're doing a pre-sale where you need stamped tickets to participate in the pre-sale, let's calculate totalBalanceForUser so user can see if they can participate
        if (this.saleType === saleTypes.PreSale && this.contractIsForPresaleBasedOnTicket()) {
          if (!this.contractForPreSaleTicket) this.contractForPreSaleTicket = await utils_contract.getTicketOrPassContract(provider)
          this.totalBalanceForUser = 0
          const tokenIds: number[] = await this.contractForPreSaleTicket.getTokensOfOwner(utils_ether.getCurrentAccount())
          for (const tokenId of tokenIds) this.totalBalanceForUser += Number(await this.contractForPreSaleTicket.balanceOfTicket(tokenId))
        }

        // if we're doing a pre-sale where you need to own another NFT as a ticket to participate in the pre-sale, let's calculate ownsPassForPreSale so user can see if they can participate
        if (this.saleType === saleTypes.PreSale && this.contractIsForPresaleBasedOnAnotherNFT() && !this.ownsPassForPreSale) {
          if (!this.contractForPreSaleTicket) this.contractForPreSaleTicket = await utils_contract.getTicketOrPassContract(provider)
          this.ownsPassForPreSale = false
          const numberOfPasses: number = await this.contractForPreSaleTicket.balanceOf(utils_ether.getCurrentAccount())
          this.ownsPassForPreSale = numberOfPasses > 0
        }

        if (!this.alreadySubscribedToMintEvents) {
          contract.on("Mint", async (to: string, num: BigNumber, value: BigNumber, totalSupply: BigNumber) => {
            // console.log(typeof to)                  // string
            // console.log(typeof num)                 // object
            // console.log(typeof value)               // object
            // console.log(typeof totalSupply)         // object
            const n: number = num.toNumber()
            const ts: number = totalSupply.toNumber()

            if (!this.mintEventsSoFar.includes(ts)) {   // to prevent us from toasting duplicates
              this.mintEventsSoFar.push(ts)
              const tokenIds: number[] = []
              for (let i = 0; i < n; i++) tokenIds.push(ts - n + i)
              if (this.contractType === 'Ticket') {
                if (to.toLocaleLowerCase() !== this.user.address.toLocaleLowerCase()) {
                  if (this.mintable) {
                    const name: string = await utils_ether.getProvider().lookupAddress(to) || utils_ether.niceAddress(to)           // hopefully lookupAddress isn't expensive.
                    utils.toast(this, 'Minted', `${name} just minted a ticket with ${n} stamp${n === 1 ? '.' : 's.'}`, 3000, 'info', 'b-toaster-bottom-left');
                  }
                } else {
                  utils.toast(this, 'Minted', `You successfully minted a ticket with ${n} stamp${n === 1 ? '.' : 's.'}`, 5000, 'success');
                  this.mintingInProgress = false 
                }
              } else {
                let mintedString: string
                if (n === 1) mintedString = `minted token ${tokenIds[0]}`;
                else mintedString = `minted ${n} tokens ${tokenIds.join(', ')}`
                if (to.toLocaleLowerCase() !== this.user.address.toLocaleLowerCase()) {
                  if (this.mintable) {
                    const name: string = await utils_ether.getProvider().lookupAddress(to) || utils_ether.niceAddress(to)           // hopefully lookupAddress isn't expensive.
                    utils.toast(this, 'Minted', `${name} just ${mintedString}.`, 3000, 'info', 'b-toaster-bottom-left');
                  }
                } else {
                  utils.toast(this, 'Minted', `You successfully ${mintedString}.`, 5000, 'success');
                  this.mintingInProgress = false 
                }
              }
            }
          });
          this.alreadySubscribedToMintEvents = true
        }

        this.failedLastTime = false
        this.fetched = true
      } catch (e) {
        // if (!this.failedLastTime) utils.toast(this, 'Error', `Smart contract not found - are you on the wrong Ethereum network?`, 5000);
        if (this.failedLastTime) this.numAutoFetchFailuresInARow++
        this.failedLastTime = true
        console.error(e.toString())
      }

    } else {
      // for folks who aren't connected via metamask or if connection slow to establish
      this.saleType = this.PRESALE_DROP_DATE && new Date() < this.PRESALE_DROP_DATE ? saleTypes.PreSale : saleTypes.MainSale
    }

    // do it again in X seconds
    let WAIT_MS: number = 1000
    if (!this.mintable) {
      WAIT_MS = 5000
      if (!this.soldOut) {
        const dropDateToUse: Date = this.saleType === saleTypes.PreSale && this.PRESALE_DROP_DATE ? this.PRESALE_DROP_DATE : this.MAINSALE_DROP_DATE
        if (dropDateToUse.getTime() - new Date().getTime() < 60000) WAIT_MS = 1000    // we are 60s away from drop so refresh faster
      }
    }
    WAIT_MS += (this.numAutoFetchFailuresInARow * 1000)     // backoff if having issues
    this.timer = setTimeout(() => {
      this.checkMintStats()
    }, WAIT_MS)

  }

}

