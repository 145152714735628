import { ethers, providers, utils as etherUtilsModule, Contract as ContractModule } from "ethers";

let DEBUGGING: boolean = process.env.NODE_ENV === 'development'
export function _setDebugging(d: boolean): void { DEBUGGING = d }

export let owner: string = null
export let nameOfSale: string = ''
export let nameOfTicketOrPass: string = ''

// from hardhat artifacts directory (after running npx hardhat compile), but I added contractAddress field to the json's manually (after deploying to get contractAddress)
// ADD NEW CONTRACTS HERE
// import contract_CoolPets from '@/contracts/CoolPets.json';
// import contract_CoolPetsPass from '@/contracts/CoolPetsPass.json';
// import contract_PielandPass from '@/contracts/PielandPass.json';
// import contract_PielandOctopie from '@/contracts/PielandOctopie.json';
// import contract_PielandUpgradeable from '@/contracts/PielandUpgradeable-rinkeby.json';
import contract_PielandUpgradeable from '@/contracts/PielandUpgradeable.json';

// these get set in the next if/else stanza
let contractAddressForMainContract: string 
let abiForMainContract: any
let contractAddressForTicketOrPassContract: string
let abiForTicketOrPassContract: any

// FOR EACH DROP, CHANGE THIS IF/ELSE STANZA
const SELLING_ONLY_PASSES_FOR_NOW: boolean = false
if (SELLING_ONLY_PASSES_FOR_NOW) {
  // selling only passes right now
  // contractAddressForMainContract = contract_PielandPass.contractAddress
  // abiForMainContract = contract_PielandPass.abi
} else {
  // now we're selling the main sale, so we have possibly two contracts to use
  contractAddressForMainContract = contract_PielandUpgradeable.contractAddress
  abiForMainContract = contract_PielandUpgradeable.abi
  // applicable only if the main sale depends on a ticket or pass for its presale 
  // contractAddressForTicketOrPassContract = contract_PielandPass.contractAddress
  // abiForTicketOrPassContract = contract_PielandPass.abi
}

// pass in provider for read-only access and signer for write access as the signer
export async function getMainContract(signerOrProvider: ethers.providers.Provider | ethers.Signer): Promise<ethers.Contract> {
  const contract: ethers.Contract = new ethers.Contract(contractAddressForMainContract, abiForMainContract, signerOrProvider); 
  owner = await contract.owner()
  nameOfSale = await contract.name()
  console.log(`getMainContract() called on ${contractAddressForMainContract} and got ${nameOfSale}/${await contract.symbol()} of type ${await contract.contractType()}`)
  return contract
}
export async function getTicketOrPassContract(signerOrProvider: ethers.providers.Provider | ethers.Signer): Promise<ethers.Contract> {
  if (!contractAddressForTicketOrPassContract) return null
  const contract: ethers.Contract = new ethers.Contract(contractAddressForTicketOrPassContract, abiForTicketOrPassContract, signerOrProvider); 
  nameOfTicketOrPass = await contract.name()
  console.log(`getTicketOrPassContract() called on ${contractAddressForTicketOrPassContract} and got ${nameOfSale}/${await contract.symbol()} of type ${await contract.contractType()}`)
  return contract
}

