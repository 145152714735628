import * as utils from './utils'
import { NFTUser } from './NFTUser'

const DEBUGGING = process.env.NODE_ENV === 'development'
const KEY_PREFIX = 'UC/'
const CACHE_LIFE_MS: number = 300000   // 5m

// client-side only - we need this to cache users' details (names, followedBy, following), otherwise:
//  1) server would need to send names of users along with every call that has users (totally doable, but a little more DB intensive on users collection (though could use memcache on server side))
//  2) we change Story, TokenUser and Comment classes to house full TokenUser's rather than userids (but then subject to staleness when users' change names or details)
//  3) client makes a ton of calls to server for users' names all the time

// TODO: consider applying TTL to each user entry
interface fullCache {
  userCache: { [id: string]: cacheEntry },
  whenRelatedFetched: number
}
interface cacheEntry {
  user: NFTUser,
  whenAdded: number
}

class UserCache {
  private cache: fullCache;

  constructor() {
    const cacheString: string = localStorage.getItem(KEY_PREFIX + 'cache')
    if (!cacheString) {
      if (DEBUGGING) console.log('Creating user cache for the very first time')
      this.cache = {
        userCache: {},
        whenRelatedFetched: null
      }
    } else {
      this.cache = JSON.parse(cacheString)
      if (DEBUGGING) console.log(`Loading user cache from localStorage with ${Object.keys(this.cache).length} users in it.`)
    }
  }

  public add(user: NFTUser): void {
    const cacheEntry: cacheEntry = {
      user,
      whenAdded: new Date().getTime()
    }
    this.cache.userCache[user.getIdAsString()] = cacheEntry
    localStorage.setItem(KEY_PREFIX + 'cache', JSON.stringify(this.cache))
  }

  // call this to get a user from cache (and fetch from server only if necessary)
  public async get(id: string, forceServerFetch: boolean): Promise<NFTUser> {
    let user: NFTUser
    const nowTimeMS: number = new Date().getTime()
    const cacheEntry: cacheEntry = this.cache.userCache[id]
    if (cacheEntry && !forceServerFetch && (nowTimeMS - cacheEntry.whenAdded < CACHE_LIFE_MS)) {
      user = new NFTUser(cacheEntry.user)
      if (DEBUGGING) console.log(`UserCache.get() returned ${id} from cache`)
      return user
    }

    // fetch from server
    let url: string = utils.getURLprefix() + "/api/users";
    url += '/' + id
    if (DEBUGGING) console.log(`UserCache.get() fetching ${id}`)
    try {
      const response: any = await utils.makeGetCall(url);
      user = response.data.user;
      if (user) {
        user = new NFTUser(user);
        user.email = response.data.email
        this.add(user)
        if (DEBUGGING) console.log(`UserCache.get() fetched 1 user ${id}`)
        return user
      } else {
        console.log(`UserCache.get() did not get a user back for ${id}`)
      }
    } catch (error) {
      console.log(`UserCache.get() got ${error}`)
    }
    return null
  }

  // call this when you want to fill your cache with your connections.
  //  For a service like Twitter, LinkedIn or FB this would be ridiculous, but for StoryHome, this should be fine
  public async fetchRelated(forceServerFetch: boolean): Promise<void> {
    const nowTimeMS: number = new Date().getTime()
    if (!forceServerFetch && this.cache.whenRelatedFetched && (nowTimeMS - this.cache.whenRelatedFetched < CACHE_LIFE_MS)) return

    // fetch from server
    let users: NFTUser[]
    const url: string = utils.getURLprefix() + "/api/users";
    if (DEBUGGING) console.log(`UserCache.fetchRelated() fetching...`)
    try {
      const response: any = await utils.makeGetCall(url);
      users = response.data.users;
      if (users) {
        this.cache.whenRelatedFetched = nowTimeMS
        users = users.map((po) => new NFTUser(po));
        for (const u of users) this.add(u)
      }
      if (DEBUGGING) console.log(`UserCache.fetchRelated() fetched ${users.length} users`)
    } catch (error) {
      console.log(`UserCache.fetchRelated() got ${error}`)
    }
  }

  // call this when you have a big list of users' ids you need to have added to cache
  public async fetchMany(ids: string[]): Promise<void> {
    ids = ids.filter((id: string) => this.cache[id] == null)
    if (ids.length === 0) return

    // fetch from server
    let users: NFTUser[]
    let url: string = utils.getURLprefix() + "/api/users";
    url += `?ids=${ids.join(',')}`
    if (DEBUGGING) console.log(`UserCache.fetchMany() fetching ${ids.length}...`)
    try {
      const response: any = await utils.makeGetCall(url);
      users = response.data.users;
      if (users) {
        users = users.map((po) => new NFTUser(po));
        for (const u of users) this.add(u)
      }
      if (DEBUGGING) console.log(`UserCache.fetchMany() fetched ${users.length} users`)
    } catch (error) {
      console.log(`UserCache.fetchMany() got ${error}`)
    }

  }

}

export const globalUC: UserCache = new UserCache();


(async () => {
})();
