






























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import NavbarComponent from '@/components/NavbarComponent.vue'; // @ is an alias to /src
import AlertComponentBottom from '@/components/AlertComponentBottom.vue'; // @ is an alias to /src
import AlertComponentTop from '@/components/AlertComponentTop.vue'; // @ is an alias to /src
const ModalWrapperComponent = () => import('@/components/ModalWrapperComponent.vue')
import * as utils from '@/utils'
import * as utils_tracker from '@/utils_tracker'
import { NFTUser } from '@/NFTUser';
import CookieLaw from 'vue-cookie-law'

@Component({
  name: 'App',
  components: {
    NavbarComponent,
    AlertComponentBottom,
    AlertComponentTop,
    ModalWrapperComponent,
    CookieLaw
  },
})
export default class App extends Vue {
  // public reactive member variables available to the html template
  public MAINTENANCE: boolean = false

  // public functions available to the html template
  public get isAuthenticated(): boolean { return this.$store.getters.isAuthenticated }
  public get loading(): boolean { return this.$store.state.loading }
  public get user(): NFTUser { return this.$store.getters.userObject }
  public get userId(): string { return this.$store.getters.userId }

  public get supportEmail(): string { return 'support@pieland.io'}
  public get siteName(): string { return 'Pie Land'}

  public clickedFAQ() {
    (this.$refs.FAQComponent as any).show(`Frequently Asked Questions`, 'lg', null)
  }

  public async clickedPrivacyPolicy() {
    try { await this.$router.push({ name: 'privacyPolicy'}) } catch (Error) {}
  }

  public acceptedCookieLaw() {
    utils_tracker.track('User AcceptedCookieLaw', null)
  }

  // private functions not available directly to HTML template
  private async created() {
    if (this.$store.state.devmode) console.log(`${this.$options.name} created()`)

    // store utm parameters into localStorage so that we always know them
    utils.saveUTMsToLocalStorage(this.$route.query)

    // sync clocks
    await utils.setMsTimeServerIsAhead(this)

    // set/get uniqueClientID
    const ucid: number = utils.getAndSetUniqueClientID()

  }

  private async mounted() {
    if (this.$store.state.devmode) console.log(`${this.$options.name} mounted()`)
  }

}
