import { render, staticRenderFns } from "./NavbarComponent.vue?vue&type=template&id=663c53c5&scoped=true&"
import script from "./NavbarComponent.vue?vue&type=script&lang=ts&"
export * from "./NavbarComponent.vue?vue&type=script&lang=ts&"
import style0 from "./NavbarComponent.vue?vue&type=style&index=0&id=663c53c5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "663c53c5",
  null
  
)

export default component.exports