






































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { NFTUser } from '@/NFTUser'
import { NFT } from '@/NFT'
import * as utils from '@/utils';
import * as utils_auth from '@/utils_auth';
import * as utils_tracker from '@/utils_tracker';
import ModalWrapperComponent from '@/components/ModalWrapperComponent.vue';   // @ is an alias to /src
import NFTGridComponent from '@/components/NFTGridComponent.vue';             // @ is an alias to /src
import * as utils_ether from '../utils_ether';
import * as utils_ethers from '../utils_ethers';
import * as utils_contract from '../utils_contract';
import { ethers, BigNumber } from "ethers";
import { Project } from "../Project";
import { Showcase } from "../Showcase";
import { Competition } from "../Competition";
import { IPaginationResult } from '@/PaginationResult'

const OCTOPIE_CONTRACT = '0xd3cd44f07744da3a6e60a4b5fda1370400ad515b'
const OVENS_CONTRACT = '0xb38281fb31138Cc609511b3D83e404B783B33922'
const MAPS_CONTRACT = '0xb3E88f3f9c80b4De276b61bFee4B65806075d38c'

interface PielandSummaryCounts {
  total: number,

  // slices
  numDoughbots: number,
  numExpieriments: number,
  numPieborgs: number,
  numPielanders: number,
  numPierates: number,
  numPiesces: number,
  numPiesons: number,
  numVampiers: number,
  numZompies: number,
  numTheBaked: number,
  numBurned: number,
  // numUniques: number,
  // numDoughdiacs: number,
  // numGuardians: number,
  numOther: number,      // uniques, dougdiacs + guardians

  // 1of1s
  numBW: number,
  numGold: number,
  numRainbows: number,
  numLeaders: number,
  numLegendaries: number,
  numSpecial: number
}

@Component({
  name: 'Account',
  components: { NFTGridComponent, ModalWrapperComponent }
})

export default class Account extends Vue {
  // prop reactive member variables publically available to the html template

  // public reactive member variables available to the html template
  public get loading(): boolean { return this.$store.state.loading }
  public get isAuthenticated(): boolean { return this.$store.getters.isAuthenticated }
  public get user(): NFTUser { return this.$store.getters.userObject }

  public forceContractAddress: string = null   // query string parameter to change the contract address (good for testing)
  public wallet: string = null                // query string parameter to override what wallet to look at
  public preview: boolean = false             // query string parameter to enable preview features

  // public COMPIETITION_FULL_NAME: string = "028: Pieland Trick-Or-Treat"
  // public COMPIETITION_SHORT_NAME: string = "Pieland Trick-Or-Treat"
  // public COMPIETITION_NUM_PIES_MIN: number = 1
  // public COMPIETITION_NUM_PIES_MAX: number = 5   // 8314 is essentially no max

  public nftFilters: Array<{ label: string, filter: string }> = [
    { label: 'Octopie', filter: OCTOPIE_CONTRACT },
    { label: 'Ovens', filter: OVENS_CONTRACT },
    { label: 'Maps', filter: MAPS_CONTRACT }
  ]

  public nftFilter: { label: string, filter: string } = this.nftFilters[0]

  public pieFilters: Array<{ label: string, filter: string }> = [
    { label: 'My Pies', filter: 'MINE' },
    { label: 'All Pies', filter: 'ALL' },
    { label: 'Other Wallet', filter: 'SPECIFIC_WALLET' },
  ]
  public pieFilter: { label: string, filter: string } = this.pieFilters[0]

  public sliceFilters: Array<{ label: string, filter: string }> = [
    { label: 'All Slices', filter: 'ALL_SLICES' },
    { label: 'Doughbots', filter: 'Doughbots' },
    { label: 'Expieriments', filter: 'Expieriments' },
    { label: 'Pieborgs', filter: 'Pieborgs' },
    { label: 'Pielanders', filter: 'Pielanders' },
    { label: 'Pierates', filter: 'Pierates' },
    { label: 'Piesces', filter: 'Piesces' },
    { label: 'Piesons', filter: 'Piesons' },
    { label: 'Vampiers', filter: 'Vampiers' },
    { label: 'Zompies', filter: 'Zompies' },
    { label: 'The Baked', filter: 'The Baked' },
    { label: 'Burned', filter: 'Burned' },
    { label: 'Other', filter: 'Other' }
  ]
  public sliceFilter: { label: string, filter: string } = this.sliceFilters[0]

  public query: string = null
  public queryUsed: string = null

  public sortOrders: Array<{ label: string, sort: string }> = [
    { label: 'By Token Id', sort: 'TokenId_ASC' },
    { label: 'Rarest First', sort: 'Rarity_DESC' },
  ]
  public sortOrder: { label: string, sort: string } = this.sortOrders[0]

  // the raw NFTs
  public paginationResult: IPaginationResult = null
  public nfts: NFT[] = null

  // Feb 7, 2024
  public competition: Competition = null

  private lastFetchSucceeded: boolean;
  private pageNumberToLoadNext: number = 1
  private allNFTsLoaded: boolean = false

  // the project behind the nfts (for trait rarities) - DEFUNCT
  public project: Project = null

  // summary
  public summaryCounts: PielandSummaryCounts = null

  // compietition things
  public selecting: boolean = false
  public selectedNFTs: string[] = []          // mongo ids of nfts

  public getShortCompetitionName(): string {
    if (!this.competition) return ''
    // remove possibly preceding number followed by colon
    return this.competition.name.replace(/^\d+:/, '').trim()
  }

  public async onQueryChanged() {
    if (this.$store.state.devmode) console.log(`onQueryChanged() with ${this.query}`)
    if (this.query.length === 0) {
      if (this.queryChangedTimer) clearTimeout(this.queryChangedTimer)
      this.queryChangedTimer = setTimeout(async () => { this.onQueryEntered() }, 500)
    } else {
      if (this.queryChangedTimer) clearTimeout(this.queryChangedTimer)
      this.queryChangedTimer = setTimeout(async () => { this.onQueryEntered() }, 1000)
    }
  }

  public async onQueryEntered() {
    if (this.queryChangedTimer) {
      clearTimeout(this.queryChangedTimer)
      this.queryChangedTimer = null
    }
    if (this.$store.state.devmode) console.log(`onQueryEntered() with ${this.query}`)
    this.queryUsed = this.query
    this.nfts = []
    this.summaryCounts = null
    this.paginationResult = null
    this.pageNumberToLoadNext = 1
    await this.fetchData()
  }

  public showingPieland(): boolean { return this.nftFilter.filter === OCTOPIE_CONTRACT && !this.forceContractAddress }
  public showingOvens(): boolean { return this.nftFilter.filter === OVENS_CONTRACT && !this.forceContractAddress }
  public showingMaps(): boolean { return this.nftFilter.filter === MAPS_CONTRACT && !this.forceContractAddress }


  @Watch('nftFilter')
  public async onChangedNftFilter(nftFilter: { label: string, filter: string }) {
    this.setFilterWording()

    this.nfts = []
    this.summaryCounts = null
    if (nftFilter.filter === 'ALL') this.sortOrder = this.sortOrders[1]    // nice to see all pies by rarity

    this.paginationResult = null
    this.pageNumberToLoadNext = 1

    // clear query when switching NFTs (seems to make sense)
    this.query = null
    this.queryUsed = this.query

    await this.fetchData()
  }

  @Watch('pieFilter')
  public async onChangedPieFilter(pieFilter: { label: string, filter: string }) {
    if (pieFilter.filter === 'MINE' && !this.user) {
      utils.toast(this, 'Wallet Not Connected', 'Connect your wallet to view My Pies', 5000, 'warning')
      return
    }

    if (pieFilter.filter === 'SPECIFIC_WALLET') {
      // popup text capture box to populate this.wallet
      // this.wallet = null
      this.showWalletInputPopup()
      return
    }

    this.nfts = []
    this.summaryCounts = null
    if (pieFilter.filter === 'ALL') {
      this.sortOrder = this.sortOrders[1]    // nice to see all pies by rarity
      this.turnSelectingModeOnOff()
    }

    this.paginationResult = null
    this.pageNumberToLoadNext = 1
    await this.fetchData()
  }

  @Watch('sliceFilter')
  public async onChangedSliceFilter(sliceFilter: { label: string, filter: string }) {
    this.nfts = []
    this.summaryCounts = null
    this.paginationResult = null
    this.pageNumberToLoadNext = 1
    await this.fetchData()
  }

  @Watch('sortOrder')
  public async onChangedSort(sortOrder: { label: string, sort: string }) {
    this.sortOrder = sortOrder
    if (this.allNFTsLoaded) {
      this.sortNFTs();
    } else {
      this.paginationResult = null
      this.pageNumberToLoadNext = 1
      await this.fetchData()
    }
  }

  public async userChoseConnect() {
    const ethereum = (window as any).ethereum
    if (!ethereum) {
      // TODO: detect browser to customize error message
      utils.toast(this, 'Wallet Not Detected', 'Install the MetaMask browser extension before connecting.', 5000, 'warning')
      return
    }
    await utils_ether.connect()
  }

  public showCompietition(): boolean {
    if (!this.user) return false
    if (!this.nfts) return false
    if (this.pieFilter.filter !== 'MINE') return false
    if (!this.competition) return false
    return true
  }

  public showSlices(): boolean {
    return this.showingPieland() || this.showingOvens() || this.showingMaps()
  }

  public async infiniteHandler(state: any): Promise<void> {
    const numReturned: number = await this.fetchData(true)
    if (numReturned === null) return
    if (numReturned === 0) {
      if (this.$store.state.devmode) console.log(`infiniteHandler(): state.complete after getting 0 items`)
      this.allNFTsLoaded = true
      state.complete()
    } else {
      this.allNFTsLoaded = false
      state.loaded();
    }
  }

  public showWalletInputPopup() {
    const dynamicProps: any = {}
    dynamicProps.placeHolderText = 'wallet address';
    (this.$refs.WalletInputComponent as ModalWrapperComponent).show('Enter Wallet Address', 'sm', dynamicProps);
  }

  public async walletInputChangedModalShow(componentName: string, showing: boolean, payload: { ok: boolean, textInput: string }) {
    if (showing) return
    if (!payload.ok) {
      if (this.user) this.pieFilter = this.pieFilters[0]; else this.pieFilter = this.pieFilters[1];
      return
    }
    const wallet: string = payload.textInput
    if (wallet) {
      this.wallet = wallet
      this.pageNumberToLoadNext = 1
      this.turnSelectingModeOnOff()
      await this.fetchData()
    }
  }

  // private, non-reactive member variables
  private queryChangedTimer: NodeJS.Timeout = null
  private lastURL: string = null  

  private async setFilterWording() {
    if (this.showingPieland()) {
      this.pieFilters[0].label = 'My Pies'
      this.pieFilters[1].label = 'All Pies'
    } else if (this.showingOvens()) {
      this.pieFilters[0].label = 'My Ovens'
      this.pieFilters[1].label = 'All Ovens'
    } else if (this.showingMaps()) {
      this.pieFilters[0].label = 'My Maps'
      this.pieFilters[1].label = 'All Maps'
    } else {
      this.pieFilters[0].label = 'Mine'
      this.pieFilters[1].label = 'All'
    }

    if (this.forceContractAddress) {
      if (this.nftFilters.length < 3) {
        this.nftFilters.push({ label: 'Other', filter: null })
      }
      this.nftFilter = this.nftFilters[this.nftFilters.length - 1]
    }
  }

  // private functions not available directly to HTML template
  private async mounted() {
    if (this.$store.state.devmode) console.log(`${this.$options.name} mounted()`)

    utils_tracker.page(this.$options.name)

    if (this.$route.query.forceContractAddress) this.forceContractAddress = this.$route.query.forceContractAddress as string
    if (this.$route.query.wallet) this.wallet = this.$route.query.wallet as string
    if (this.$route.query.preview) this.preview = true

    // await this.fetchData()

    this.setFilterWording()

    utils_ether.event.$on(utils_ether.MSGS.ACCOUNT_CHANGED, async () => {
      setTimeout(async () => {
        this.paginationResult = null
        this.pageNumberToLoadNext = 1
        if (this.user) this.pieFilter = this.pieFilters[0]; else this.pieFilter = this.pieFilters[1];   // May 1, 2023
        await this.fetchData()
      }, 500)
    })

    setTimeout(async () => {
      if (!this.loading && !this.nfts) await this.fetchData()
    }, 1000)

  await this.fetchCompetitions()

  }

  private async beforeDestroy() { 
    if (this.$store.state.devmode) console.log(`${this.$options.name} beforeDestroy()`)

    if (this.queryChangedTimer) clearTimeout(this.queryChangedTimer)
    this.queryChangedTimer = null
  }

  public turnSelectingModeOn() {
    this.selecting = true
    const nftGridComponents: NFTGridComponent[] = this.$refs.nftGridComponents as NFTGridComponent[]
    if (nftGridComponents) for (const nftGridComponent of nftGridComponents) nftGridComponent.setSelectMode(true)
  }

  public turnSelectingModeOnOff() {
    this.selecting = false
    const nftGridComponents: NFTGridComponent[] = this.$refs.nftGridComponents as NFTGridComponent[]
    if (nftGridComponents) for (const nftGridComponent of nftGridComponents) nftGridComponent.setSelectMode(false)
  }

  public async submitEntry() {
    try {
      this.$store.commit('setLoading', { loading: true });

      const url: string = utils.getURLprefix() + `/api/showcases`;
      const postData: any = { address: utils_ether.getCurrentAccount().toLocaleLowerCase(), name: this.competition.name, nfts: this.selectedNFTs }
      const response: any = await utils.makePostCall(url, postData)
      utils.hideToasts(this)
      if (!response || !response.data) {
        utils.toast(this, 'Error', 'No response from server')
        return
      }
      if (response.data.error) utils.toast(this, 'Error', response.data.error)
      const showcase: Showcase = response.data.showcase    // eh, don't do anything with this...
      const status: string = response.data.status
      const validated: boolean = response.data.validated
      const problemString: string = response.data.problemString
      const howManyCompletions: number = response.data.howManyCompletions   // show this to user?
      const successString: string = response.data.successString

      if (validated === true) {
        if (status === 'inserted') utils.toast(this, 'Success', `Your entry was submitted and validated!`)
        if (status === 'replaced') utils.toast(this, 'Success', `Your new entry was submitted and validated!`)
        if (successString) utils.toast(this, 'Points', successString, 30000)
      } else if (validated == false ) {
        utils.toast(this, 'Error', problemString, 30000)
      } else {
        // didn't try validating
        if (status === 'inserted') utils.toast(this, 'Success', `Your entry was submitted!`)
        if (status === 'replaced') utils.toast(this, 'Success', `Your new entry was submitted!`)
      }

    } catch (error) {
      if (error.toString().includes('Network Error') || error.toString().includes('status code 502')) utils.toast(this, 'Error', 'Undergoing Maintenance'); else utils.toast(this, 'Error', 'Could not load url')
      if (this.$store.state.devmode) console.log(error);
    } finally {
      this.$store.commit("setLoading", { loading: false });
    }

    this.turnSelectingModeOnOff()

  }  

  public userChangedChosen(nft: string, chosen: boolean) {
    this.selectedNFTs = this.selectedNFTs.filter(n => n !== nft)
    if (chosen) {
      this.selectedNFTs.push(nft)
      if (this.$store.state.devmode) console.log(nft);

    }
  }

  private sortNFTs() {
    if (!this.nfts) return
    console.log(`Sorting ${this.nfts.length} nfts`)
    if (this.sortOrder.sort === 'TokenId_ASC') this.nfts = this.nfts.sort((n1, n2) => Number(n1.token_id) - Number(n2.token_id))
    if (this.sortOrder.sort === 'Rarity_DESC') this.nfts = this.nfts.sort((n1, n2) => {
      if (n2.rarityScore < n1.rarityScore) return -1
      if (n2.rarityScore > n1.rarityScore) return 1

      if (!n1.metadata || !n1.metadata.name || !n2.metadata || !n2.metadata.name) return 0

      // nane is more complicated because of # and non-leading zeros
      const n1base: string = n1.metadata.name.split('#')[0]
      const n2base: string = n2.metadata.name.split('#')[0]
      if (n1base < n2base) return -1
      if (n1base > n2base) return 1

      // sort based on serial # if slice is the same (this applies only in practice to red envelopes because they all have same rarity)
      try {
        const n1serial: number = Number(n1.metadata.name.split('#')[1])
        const n2serial: number = Number(n2.metadata.name.split('#')[1])
        if (n1serial < n2serial) return -1
        if (n2serial < n1serial) return 1
        return 0
      } catch {
        return 0
      }
    })
  }

  private async fetchCompetitions(): Promise<void> {
    if (this.$store.state.devmode) console.log(`fetchCompetitions()...`)
    try {
      let url: string = utils.getURLprefix() + `/api/competitions/?futureEndDateOnly=true`;
      const response: any = await utils.makeGetCall(url);
      if (response.data.error) {
        utils.toast(this, 'Error', `Could not load competitions`, 5000);
        return
      }
      // go through competitions and find the one we want (i.e. the one that is running, but if preview, the one that is first and upcoming)
      if (response.data.competitions && response.data.competitions.length > 0) {
        const competitions: Competition[] = response.data.competitions.map((po: any) => new Competition(po))
        if (this.preview) {
          this.competition = competitions[competitions.length - 1]
        } else {
          for (const competition of competitions) {
            if (competition.running) {
              this.competition = competition
              break
            }
          }
        }
      }
    } catch (e) {
       utils.toast(this, 'Error', `Could not load competitions`, 5000);
      console.error(e.toString())
    } finally {
    }
  }

  private async fetchData(calledByInfiniteHandlerOrTimer?: boolean): Promise<number> {
    if (this.pieFilter.filter === 'SPECIFIC_WALLET' && !this.wallet) return null;
    // if (this.loading) return null

    // walletToUse can be
    //  1) this.wallet (entered manually by user)
    //  2) * which means all pies
    //  3) utils_ether.getCurrentAccount().toLocaleLowerCase()
    let walletToUse: string 
    if (this.pieFilter.filter === 'SPECIFIC_WALLET') walletToUse = this.wallet
    if (this.pieFilter.filter === 'ALL') walletToUse = '*'
    if (this.user && utils_ether.ready() && this.pieFilter.filter === 'MINE') walletToUse = utils_ether.getCurrentAccount().toLocaleLowerCase()
    if (!walletToUse) {
      this.pieFilter = this.pieFilters[1]
      walletToUse = '*'
    }

    // hack 
    if (!this.nfts && this.pieFilter.filter === 'ALL') this.sortOrder = this.sortOrders[1]    // nice to see all pies by rarity

    if (this.$store.state.devmode) console.log(`fetchData(${calledByInfiniteHandlerOrTimer}) ${walletToUse}...`)

    try {
      const page: number = this.pageNumberToLoadNext

      const contractAddress: string = this.forceContractAddress || this.nftFilter.filter

      let url: string = utils.getURLprefix() + `/api/nfts/${contractAddress}`;
      url += `?page=${page}`;
      url += `&sort=${this.sortOrder.sort}`     // TokenId_ASC or RARITY_DESC
      url += `&ownerAddress=${walletToUse}`
      if (this.sliceFilter.filter !== 'ALL_SLICES') url += `&slice=${this.sliceFilter.filter}`
      if (this.queryUsed) url += `&query=${encodeURIComponent(this.queryUsed)}`

      // prevent doing multiple of the same fetch (not sure yet root cause)
      if (this.loading && this.lastURL === url) return
      this.$store.commit('setLoading', { loading: true });
      this.lastURL = url

      const response: any = await utils.makeGetCall(url);
      if (response.data.error) {
        utils.toast(this, 'Error', `Could not load NFTs for ${contractAddress}`, 5000);
        return
      }

      // pagination result
      if (page === 1) this.allNFTsLoaded = false
      this.paginationResult = response.data.paginationResult
      if (this.paginationResult.totalCurrentResults === 0) this.allNFTsLoaded = true

      // And, if page 1, server will return slice summary counts
      if (response.data.summaryCounts) {
        this.summaryCounts = response.data.summaryCounts
      }

      // actual nfts
      let newRawItems: NFT[] = response.data.nfts
      if (newRawItems) {
        newRawItems = newRawItems.map((po) => new NFT(po));
        if (page === 1) this.nfts = newRawItems
        else this.nfts = this.nfts.concat(newRawItems)

        this.pageNumberToLoadNext++
        this.lastFetchSucceeded = true

        return newRawItems.length
      } else {
        this.lastFetchSucceeded = false;
        return 0
      }

      // now fetch the project from our own DB so that we can show trait rarities for each nft when hovered over
      // NOPE: don't need to do this because server side puts in attribute rarities into each NFT now
      /*
      const contractAddress: string = FAKE_REVEAL ? 'pieland-test-run-06' : mainContract.address
      const url: string = utils.getURLprefix() + `/api/projects/${contractAddress}`;
      const response: any = await utils.makeGetCall(url);
      if (response.data.error) {
        utils.toast(this, 'Error', response.data.error)
        this.$store.commit("setLoading", { loading: false });
        return
      }
      this.project = new Project(response.data.project)
      console.log(this.project)
      */

      // TODO: paginate so we don't overload images (or stagger the images by sort order)

    } catch (e) {
      utils.toast(this, 'Error', `Smart contract not found - are you on the wrong Ethereum network?`, 5000);
      console.error(e.toString())
    } finally {
      this.$store.commit("setLoading", { loading: false });
    }

  }


}

