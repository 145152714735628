import { render, staticRenderFns } from "./Competitions.vue?vue&type=template&id=023dd4b4&scoped=true&"
import script from "./Competitions.vue?vue&type=script&lang=ts&"
export * from "./Competitions.vue?vue&type=script&lang=ts&"
import style0 from "./Competitions.vue?vue&type=style&index=0&id=023dd4b4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "023dd4b4",
  null
  
)

export default component.exports