







































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as utils from '@/utils';
import * as utils_tracker from '@/utils_tracker';
import CompetitionsListComponent from '@/components/CompetitionsListComponent.vue';                       // @ is an alias to /src
import { Competition } from '@/Competition'
import ModalWrapperComponent from '@/components/ModalWrapperComponent.vue';           // @ is an alias to /src
import * as utils_ether from '@/utils_ether';
import { NFTUser } from '@/NFTUser'

@Component({
  name: 'Competitions',
  components: { CompetitionsListComponent, ModalWrapperComponent }
})

export default class CompetitionsComponent extends Vue {
  // prop reactive member variables publically available to the html template

  // public reactive member variables available to the html template
  public lastFetchedURL: string = null;
  public competitions: Competition[] = []
  public loggedInAddress: string = null
  public allowedToUse: boolean = false

  // UI and filter options

  // public reactive member variables available to the html template
  public get loading(): boolean { return this.$store.state.loading }
  public get isAuthenticated(): boolean { return this.$store.getters.isAuthenticated }
  public get user(): NFTUser { return this.$store.getters.userObject }

  public onClickedCreate(): void {
    (this.$refs.CompetitionFormComponent as ModalWrapperComponent).show('Create a New Competition', 'lg', null)
  }

  public onClickedEdit(competition: Competition): void {
    (this.$refs.CompetitionFormComponent as ModalWrapperComponent).show('Edit a Competition', 'lg', competition)
  }

  public onClickedCSV(competition: Competition): void {
    window.open(`https://pieland.io/api/submissionsCSV?competitionId=${competition.getIdAsString()}`, "_blank"); 
  }

  public onClickedJSON(competition: Competition): void {
    window.open(`https://pieland.io/api/submissionsJSON?competitionId=${competition.getIdAsString()}&forDisplay=true&getFullNFTs=true`, "_blank"); 
  }

  public async onFormClosed(componentName: string, showing: boolean, payload: any) {
    if (showing) return
    if (payload) {
      // existence of payload means the user edited or create a competition, so let's be lazy and refetch the full list
      const competition: Competition = payload
      await this.fetchData()
    }
  }

  public async onClickedDelete(competition: Competition): Promise<void> {
    // make DELETE call to server
    try {
      this.$store.commit('setLoading', { loading: true });
      const url: string = utils.getURLprefix() + `/api/competitions/${competition.getIdAsString()}`;
      const response: any = await utils.makeDeleteCall(url);
      if (!response || !response.data) throw(new Error('Competitions.onClickedDelete() got null response or null response.data'))

      // something is wrong with the alert
      if (response.data.error) {
        utils.toast(this, 'Error', response.data.error)
      }
      

    } catch (error) {
      utils.toast(this, 'System Error', 'Could not delete competition')
      if (this.$store.state.devmode) console.error(error);
    } finally {
      this.$store.commit('setLoading', { loading: false });
    }

    // so let's refetch all the alerts now that it's deleted
    await this.fetchData()

  }

  // private, non-reactive member variables
  private lastFetchSucceeded: boolean;

  private async pathChanged(): Promise<void> {
    if (this.$store.state.devmode) console.log(`Competitions.pathChanged() ${this.$route.path}`)
  }

  // private functions not available directly to HTML template
  private async mounted() {
    if (this.$store.state.devmode) console.log(`${this.$options.name} mounted()`);

    const WHITELISTED_ADDRESSES = [
      '0xd7A103ed7ACa8673d275CD044D22AFc8b6eb8E6B'.toLocaleLowerCase(),   // Pieland
      '0xd9b927461Ac789147b1B79377BF5bBb885f1bcD3'.toLocaleLowerCase(),   // GeauxT
      '0x81f958ad9c599af6709f967c477756edc94c5589'.toLocaleLowerCase(),   // Smurf88
      '0xd10661677A58E03903cB587E2951c39d40921592'.toLocaleLowerCase(),   // Luke's OvenInTheSky account
    ]
    
    this.loggedInAddress = this.user ? this.user.address : null
    this.allowedToUse = false
    if (WHITELISTED_ADDRESSES.includes(this.loggedInAddress)) this.allowedToUse = true

    console.log(`utils_ether.getCurrentAccount() = ${utils_ether.getCurrentAccount()}`)
    console.log(`uthis.loggedInAddress = ${this.loggedInAddress}`)

    if (this.allowedToUse) await this.fetchData()

    utils_tracker.page(this.$options.name)

  }

  private async beforeDestroy() { 
    if (this.$store.state.devmode) console.log(`${this.$options.name} beforeDestroy()`)
  }

  private async fetchData(): Promise<number> {
    const url: string = utils.getURLprefix() + `/api/competitions`;

    // don't fetch if we're in the middle of fetching same url
    if (this.loading) {
      if (this.lastFetchedURL === url) {
        if (this.$store.state.devmode) console.log(`Competitions.fetchData() aborting calledByInfiniteHandler and we're already loading`)
        await utils.sleep(1000)     // wait 1 s so we don't go into 100% CPU infinite loop
        return
      }
    }

    this.$store.commit('setLoading', { loading: true });
    this.lastFetchedURL = url;

    try {
      // console.log(url)
      this.lastFetchSucceeded = false;
      const response: any = await utils.makeGetCall(url);

      // whoa, we got another call after this one, so let's abort this one cuz the latest one should take precedent
      if (this.lastFetchedURL !== url) return

      // in case old version
      if (utils.isClientOld()) {
        if (this.$store.state.devmode) console.log("Calling this.$router.go(0)");
        this.$router.go(0);
        this.$store.commit('setLoading', { loading: false });
        return;
      }

      // error check
      if (response.data.error) {
        utils.toast(this, 'Error', response.data.error)
        this.$store.commit('setLoading', { loading: false });
        return
      }
      
      // load up the moments separately from the FastFingerList object in case we want to do paging (might do everything inside FastFingerList eventually)
      let newRawItems: Competition[] = response.data.competitions
      if (newRawItems) {
        newRawItems = newRawItems.map((po) => new Competition(po));
        if (newRawItems.length > 0) this.competitions = newRawItems; else this.competitions = []
        this.lastFetchSucceeded = true
        return newRawItems.length
      } else {
        this.lastFetchSucceeded = false;
        return 0
      }

    } catch (error) {
      if (error.toString().includes('Network Error') || error.toString().includes('status code 502')) utils.toast(this, 'Error', 'Undergoing Maintenance'); else utils.toast(this, 'Error', 'Could not load url')
      if (this.$store.state.devmode) console.log(error);
      return 0
    } finally {
      this.$store.commit("setLoading", { loading: false });
    }

    this.$store.commit("setLoading", { loading: false });
  }  
}
