const DEBUGGING: boolean = process.env.NODE_ENV === 'development'
import * as telegram from './telegram'
import * as utils from './utils'
import { BaseObject, MongoObject } from './_baseClasses'

export class Competition extends MongoObject {
  protected static collectionName: string = 'competitions'           // IMPORTANT
  private static NODE_CACHE_TTL: number = 300

  public name: string = null                             // name of competition
  public lowercaseName: string = null                    // unique index
  public explanation : string = null                     // html    
  public minNFTs: number = null
  public maxNFTs: number = null
  public startDate: Date = null
  public endDate: Date = null

  // TODO: maybe
  // add rules for validation; for now, we don't accept all submissions and don't try validating them
  
  // complex, class-based fields (require to be hydrated after loading from external sources like mongo or elastic)

  // only during runtime, not purposefully stored in DB, but if stored, that's ok because we'll override it at runtime anyways
  public running?: boolean = null

  constructor(po?: object, name?: string, explanation?: string, minNFTs?: number, maxNFTs?: number, startDate?: Date, endDate?: Date) {
    super()
    if (po) this.injectPlainObject(po)
    if (name) this.name = name
    if (explanation) this.name = explanation
    if (minNFTs) this.minNFTs = minNFTs
    if (maxNFTs) this.maxNFTs = maxNFTs
    if (startDate) this.startDate = startDate
    if (endDate) this.endDate = endDate

    if (this.name) this.lowercaseName = this.name.toLocaleLowerCase()
    this.hydrate()
  }

  protected hydrate(): void {
    super.hydrate()
    if (typeof this.startDate === 'string') this.startDate = new Date(this.startDate)
    if (typeof this.endDate === 'string') this.endDate = new Date(this.endDate)
  }

  public isSafe(): boolean {
    if (this.running === null) return false
    return true
  }

}


(async () => {
})();

