


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Competition } from '@/Competition'
import * as utils from '@/utils';
import * as utils_time from '@/utils_time';

@Component({
  name: 'CompetitionsListComponent',
  components: { }
})
export default class CompetitionsListComponent extends Vue {
  // prop reactive member variables publically available to the html template
  @Prop({ default: null })
  public competition: Competition

  @Prop({ default: 0 })
  public index: number                 // in case it's ever useful for us to know where we are in the main list

  // public reactive member variables available to the html template

  // public functions available to the html template
  public get loading(): boolean { return this.$store.state.loading }

  public onEdit(competition: Competition) {
    this.$emit('clicked-edit', competition)
  }
  public onDelete(competition: Competition) {
    this.$emit('clicked-delete', competition)
  }
  public onCSV(competition: Competition) {
    this.$emit('clicked-csv', competition)
  }
  public onJSON(competition: Competition) {
    this.$emit('clicked-json', competition)
  }

  public generateDescription(competition: Competition): string {
    let description: string = `(${competition.minNFTs}-${competition.maxNFTs} NFTs)`
    if (competition.startDate && competition.endDate) description = `${utils_time.formatDateTimePT(competition.startDate)} -> ${utils_time.formatDateTimePT(competition.endDate)} · ` + description
    if (competition.running) description = `RUNNING NOW! ` + description
    return description
  }

  public generateShortExplanation(competition: Competition): string {
    return utils.abbreviatedLongString(competition.explanation, 100)
  }

  // private, non-reactive member variables

  // private functions not available directly to HTML template
  private async mounted() {
    if (this.$store.state.devmode) console.log(`${this.$options.name} mounted()`)
  }

  private async beforeDestroy() { if (this.$store.state.devmode) console.log(`${this.$options.name} beforeDestroy()`) }

}
