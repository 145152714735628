import axios, { AxiosResponse, AxiosInstance, AxiosTransformer } from 'axios'
import store from './store';
import Router from 'vue-router';
import { NFTUser } from './NFTUser';
import * as utils from './utils';
import * as utils_tracker from './utils_tracker';
import { RawLocation } from 'vue-router';
import router from './router';      // not sure why this.$router doesn't work

import settings from './settings.json';
import { ethers } from 'ethers';

export function saveAuthStuffToStoreAndLocalStorage(user: NFTUser, sessionId: string): void {
  if (!user.isSafe()) {
    console.log(`saveAuthStuffToStoreAndLocalStorage() tried to save a non-safe user`)
    this.clearAuthStuffFromStoreAndLocalStorage()
    return
  }
  store.commit('setUser', { user: user.getPlainObject() })
  if (sessionId) store.commit('setSessionId', { sessionId })
  localStorage.setItem('user', JSON.stringify(user))
  if (sessionId) localStorage.setItem('sessionId', sessionId)
}


export function clearAuthStuffFromStoreAndLocalStorage(): void {
  store.commit('setUser', { user: null })
  store.commit('setSessionId', { sessionId: null })
  localStorage.removeItem('user')
  localStorage.removeItem('sessionId')
}

// It's important that this is in local storage vs Vuex store because the redirects from external Oauth create a new Vue app
// BTw, I used RawLocation here instead of just a route name or string path because we may want to insert query strings and stuff
export function setLocationAfterLogin(location: RawLocation): void {
  localStorage.setItem('location', JSON.stringify(location))
}

export function getLocationAfterLogin(): RawLocation {
  const s: string = localStorage.getItem('location')
  if (!s) return null; else return JSON.parse(s)
}

export async function logout() {
  clearAuthStuffFromStoreAndLocalStorage();

  utils_tracker.track('User LoggedOut', null)
  utils_tracker.reset()

  try { await router.push({ name: 'home' }) } catch (Error) {}
}



(async () => {

})()
