const DEBUGGING: boolean = false
import { ObjectID } from 'bson';

// base class for all objects
export abstract class BaseObject {
  protected static ALLOW_UNEXPECTED_FIELDS: boolean = false

  // might be useful, though I know that both Mongo and Memcache will store just the plain object when the class instance is passed to them
  public getPlainObject(): object { return JSON.parse(JSON.stringify(this)) }

  // inject a plain object into this instance, while enforcing that the plain object doesn't have fields that are unexpected
  protected injectPlainObject(po: object): void {
    if (process.env.NODE_ENV === 'development' || DEBUGGING) {
      const existingFields: string[] = Object.keys(this)
      const incomingFields: string[] = Object.keys(po)
      for (const f of incomingFields) {
        if (!existingFields.includes(f)) {
          const errMsg = `injectPlainObject() encountered unexpected po field "${f}" for class "${this.constructor.name}"`
          if ((this.constructor as any).ALLOW_UNEXPECTED_FIELDS) {
            console.log(errMsg)
          } else {
          throw (new Error(errMsg))
          }
        }
      }
    }
    Object.assign(this, po)
  }

  // override if you have child items that need to be converted to class instances from po's (e.g. task does this wtih comments and bounties)
  protected hydrate(): void {}
}

// base class for objects that will be persisted to MongoDB
export abstract class MongoObject extends BaseObject {
  protected _id: ObjectID | string = null
  public created: Date = null
  public lastModified: Date = null

  // Allows code to have temporary fields inside the object.  These fields are NOT persisted to the DB.
  //  utils_mongo.insertOne() and utils_mongo.insertMany() are instrumented to achieve this.
  //  StoryCircle, for instance, uses this to add a circle field to the StoryUser object and send it to client
  public _temp: {} = {}

  public getIdAsObjectId(): ObjectID { return (typeof this._id === 'string') ? ObjectID.createFromHexString(this._id as any) : this._id }
  public getIdAsString(): string { return ((typeof this._id === 'string') || !this._id) ? (this._id as any) : this._id.toHexString() }

  protected hydrate(): void { super.hydrate(); if (typeof this.created === 'string') this.created = new Date(this.created); if (typeof this.lastModified === 'string') this.lastModified = new Date(this.lastModified) }

}

(async () => {
})();
