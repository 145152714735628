import { BaseObject, MongoObject } from './_baseClasses';
import * as utils from './utils'

const DEBUGGING: boolean = false

// https://developers.facebook.com/docs/reference/javascript/FB.getLoginStatus
export interface FBAuthResponse {
  status: 'connected'|'not_authorized'|'unknown',
  authResponse: {
    accessToken: string,                 // "EAACNXN3k..."
    expiresIn: number,                   // 5091
    signedRequest: string,               // "t_QaufR9...."
    userID: string,                      // "10105181997711843"
    graphDomain: string,                 // "facebook"
    grantedScopes: string,               // "email,public_profile"
    data_access_expiration_time: number  // 1588998909
  } 
}

export class User extends MongoObject {

  // fields
  public username: string = null
  public email: string = null
  public passwordHash: string = null                // if null, means not registered yet but we have an entry because maybe someone invited them
  public emailVerified: boolean = null
  public ipSuccessfulLogins: string[] = []
  public ipFailedLogins: string[] = []
  public lastSuccessfulLogin: Date = null
  public lastFailedLogin: Date = null
  public fwPasswordCode: string = null
  public fwPasswordExpiration: Date = null
  public emailVerificationCode: string = null
  public lastSeen: Date = null
  public invitedBy: string = null
  public invited: string[] = []
  public unsubscribeCode: string = null
  public unsubscribed: boolean = false
  public source: string = null        // .eg. organic, invited, ad
  public fullyRegistered: boolean = null
  public fbAuthResponse: FBAuthResponse = null

  // complex, class-based fields (require to be hydrated after loading from external sources like mongo or elastic)

  constructor(po?: object) {
    super()
    if (po) this.injectPlainObject(po)
    this.hydrate()
    if (!this.isSafe()) {
      console.log(`User ${this.email} not safe!`)
      throw new Error(`User ${this.email} not safe!`)
    }
  }

  protected hydrate(): void {
    super.hydrate()
    if (typeof this.lastSuccessfulLogin === 'string') this.lastSuccessfulLogin = new Date(this.lastSuccessfulLogin)
    if (typeof this.lastFailedLogin === 'string') this.lastFailedLogin = new Date(this.lastFailedLogin)
    if (typeof this.fwPasswordExpiration === 'string') this.fwPasswordExpiration = new Date(this.fwPasswordExpiration)
    if (typeof this.lastSeen === 'string') this.lastSeen = new Date(this.lastSeen)
  }

  public isSafe(): boolean {
    if (this.passwordHash) return false
    if (this.ipSuccessfulLogins && this.ipSuccessfulLogins.length > 0) return false
    if (this.ipFailedLogins && this.ipFailedLogins.length > 0) return false
    if (this.fwPasswordCode) return false
    if (this.fwPasswordExpiration) return false
    if (this.emailVerificationCode) return false
    if (this.unsubscribeCode) return false
    if (this.fbAuthResponse && this.fbAuthResponse.authResponse && this.fbAuthResponse.authResponse.accessToken) return false
    return true
  }

}

(async () => {
})();
