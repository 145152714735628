// Inspired by https://github.com/bmiller59/vue-cli-plugin-ethers
import * as telegram from './telegram';
import { ethers } from "ethers";
import { Vue } from 'vue-property-decorator';
import store from '@/store';
import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletChooserComponent from '@/components/WalletChooserComponent.vue';           // @ is an alias to /src
import * as utils from './utils';
import hash from 'hash.js'


let DEBUGGING: boolean = process.env.NODE_ENV === 'development'
export function _setDebugging(d: boolean): void { DEBUGGING = d }

export const PROVIDER_CHECK_MS = 500

// networks where ens exists: Mainet, Ropsten, Rinkeby
export const ENS_NETS = ['0x1', '0x3', '0x4']

// messages
export const MSGS = {
  NOT_CONNECTED: 'Not connected to Ethereum network',
  NOT_READY: 'Ethereum network not ready',
  NO_WALLET: 'No Ethereum wallet detected',
  ACCOUNT_CHANGED: 'Ethereum account changed',
  ETHERS_VUEX_INITIALIZED: 'Ethers vuex module initialized',
  ETHERS_VUEX_READY: 'Ethers vuex module ready'
}
// use vue as a simple event channel
export const event = new Vue()

// ethereum transactions to log
// More information: https://docs.ethers.io/ethers.js/html/api-providers.html#events
export const LOG_TRANSACTIONS = [
  'block'
  // can also be an address or transaction hash
  // [] // list of topics, empty for all topics
]



// for ethers
let ethereum
let provider: ethers.providers.Web3Provider
let chainId: string
let userWallet: ethers.providers.JsonRpcSigner
let currentAccount: string
let providerInterval: NodeJS.Timeout
let initialized: boolean
let chosenWallet: 'metamask' | 'coinbase' | 'walletconnect'

export function setChosenWallet(cw: 'metamask' | 'coinbase' | 'walletconnect') {
  chosenWallet = cw
}

//  Create WalletConnect Provider
const wc_provider = new WalletConnectProvider({ infuraId: "2ba6a2c5afca4301b8ec70d2ade0628b" });

export async function getEthereum() {
  let ethereum

  // wired off because doesn't seem to work with .request and .connected (vue-cli-plugin-ethers not made for this)
  if (chosenWallet === 'walletconnect' ) {
    await wc_provider.enable();        // QR code popup
    const provider = new ethers.providers.Web3Provider(wc_provider);
    ethereum = provider
    return ethereum
  }
  
  ethereum = (window as any).ethereum
  if (DEBUGGING && false) {
    console.log(`ethereum:`)
    console.log(ethereum)
    console.log(`isMetaMask: ${ethereum.isMetaMask}`)
    console.log(`isCoinbaseWallet: ${ethereum.isCoinbaseWallet}`)
    console.log(`providers: ${ethereum.providers ? ethereum.providers.length : null}`)
    if (ethereum.providers) {
      for (const [i, provider] of ethereum.providers.entries()) {
        console.log(`${i}: isMetaMask: ${provider.isMetaMask}, isCoinbaseWallet: ${provider.isCoinbaseWallet}`)
      }
    }
  }
  // Jan 24, 2022: if multiple wallets, choose metamask
  // TODO: allow user to choose
  if (ethereum && ethereum.providers) {
    for (const [i, provider] of ethereum.providers.entries()) {
      if (provider.isMetaMask) {
        ethereum = provider
      }
    }
  }

  return ethereum
}

async function ethereumOk(): Promise<boolean> {
  const em = await getEthereum()
  try {
    return em && em.isConnected()
  } catch (e) {
    if (store.state.devmode) {
      console.log(`ethereumOk() caught ${e.toString()}`)
    }
    return false
  }
}

// get the name of this network
export function getNetName(): string {
  switch (chainId) {
    case '0x1':
      return 'Ethereum Main Network';
    case '0x2':
      return 'Morden (deprecated)'
    case '0x3':
      return 'Ropsten Test Network'
    case '0x4':
      return 'Rinkeby Test Network'
    case '0x5':
      return 'Goerli Test Network'
    case '0x2a':
      return 'Kovan Test Network';
    case undefined:
    case null:
      return 'No Chain!'
    // if you give your ganache an id your can detect it here if you want
    default:
      return 'Unknown'
  }
}

// if this net has ens
export function hasEns() {
  return ENS_NETS.includes(chainId)
}

// get deployed address for a contract from its networks object and current network id or null
export async function getNetworkAddress(networks): Promise<string> {
  if (!networks[chainId] || !networks[chainId].address) return null
  return networks[chainId].address
}

export function getProvider(): ethers.providers.Web3Provider {
  return provider
}

export function getWallet(): ethers.providers.JsonRpcSigner {
  return userWallet
}

export async function getWalletAddress(): Promise<string> {
  const addr = userWallet && await userWallet.getAddress()
  return addr
}

export function getChainId(): string {
  return chainId
}

export function ready(): boolean {
  return !!provider && !!userWallet
}

export async function startProviderWatcher() {
  if (store.state.devmode) console.log('Starting startProviderWatcher...')

  // this should only be run when a ethereum provider is detected and set at the ethereum value above
  async function updateProvider() {
    try {
      ethereum = await getEthereum()
      if (!ethereum) return
      // set ethers provider
      provider = new ethers.providers.Web3Provider(ethereum)

      initialized = true

      // this is modeled after EIP-1193 example provided by MetaMask for clarity and consistency
      // but works for all EIP-1193 compatible ethereum providers
      // https://docs.metamask.io/guide/ethereum-provider.html#using-the-provider

      /**********************************************************/
      /* Handle chain (network) and chainChanged (per EIP-1193) */
      /**********************************************************/

      // Normally, we would recommend the 'eth_chainId' RPC method, but it currently
      // returns incorrectly formatted chain ID values.
      chainId = ethereum.chainId

      ethereum.on('chainChanged', handleChainChanged)

      /***********************************************************/
      /* Handle user accounts and accountsChanged (per EIP-1193) */
      /***********************************************************/

      const accounts: string[] = await ethereum.request({ method: 'eth_accounts' })       // in case already connected
      if (store.state.devmode) console.log(`eth_accounts: ${accounts.join(',')}`)    
      await handleAccountsChanged(accounts)
      // Note that this event is emitted on page load.
      // If the array of accounts is non-empty, you're already connected.
      ethereum.on('accountsChanged', handleAccountsChanged)
    } catch (err) {
      // Some unexpected error.
      // For backwards compatibility reasons, if no accounts are available,
      // eth_accounts will return an empty array.
      console.error('Error requesting ethereum accounts', err)
      event.$emit(MSGS.NO_WALLET)
    }
  }

  async function checkProvider() {
    // if (store.state.devmode) console.log('Running checkProvider...')

    // handle changes of availability of ethereum provider
    if (ethereum && !await ethereumOk()) {
      ethereum = null
      provider = null
      chainId = null
      currentAccount = null
      userWallet = null
      event.$emit(MSGS.NOT_READY)
    } else if (!ethereum && await ethereumOk()) {
      updateProvider()
    }
  }

  // kick it off now
  await checkProvider()
  // and set interval
  providerInterval = setInterval(checkProvider, PROVIDER_CHECK_MS)
}


function handleChainChanged(_chainId) {
  // We recommend reloading the page, unless you must do otherwise
  if (store.state.devmode) console.log('Ethereum chain changed. Reloading as recommended.')
  chainId = _chainId
  // alert('Ethereum chain has changed. We will reload the page as recommended.')
  if (ethereum && ethereum.isMetaMask) window.location.reload()
}

// could add UA too if I want even more, or if I just want to play cat & mouse
function makeUcidAddressToken(accountAddress: string): string {
  const ucid: number = utils.getAndSetUniqueClientID()
  return hash.sha1().update(ucid.toString() + accountAddress).digest('hex')
}

// For now, 'eth_accounts' will continue to always return an array
async function handleAccountsChanged(accounts: string[]) {
  if (store.state.devmode) console.log(accounts)
  if (accounts.length === 0) {
    // MetaMask is locked or the user has not connected any accounts
    if (store.state.devmode) console.log('No ethereum accounts available')
    event.$emit(MSGS.NO_WALLET)
  } else if (accounts[0] !== currentAccount) {
    currentAccount = accounts[0]
    userWallet = provider && provider.getSigner(currentAccount)
    event.$emit(MSGS.ACCOUNT_CHANGED)
    
    // create or update account in DB (and add referrer to)
    // "last cookie wins"
    // to minimize "cookie stuffing", i implemented token auth in the headers, and also i track IP in accounts on server side
    if (currentAccount) {
      // account
      const referrer: string = store.state.referrer
      let url: string = utils.getURLprefix() + `/api/accounts`;
      let postData: any = { address: currentAccount, referringAddress: referrer }
      let response: any = await utils.makePostCall(url, postData)
      if (!response || !response.data) {
        utils.toast(this, 'Error', 'No response from server')
        return
      }
      if (response.data.error) utils.toast(this, 'Error', response.data.error)
      const account = response.data.account    // eh, don't do anything with this...
      const status: string = response.data.status

      // session for kitchen auth stuff
      url = utils.getURLprefix() + `/api/sessions`
      postData = { address: currentAccount, token: makeUcidAddressToken(currentAccount) }
      response = await utils.makePostCall(url, postData)
      if (!response || !response.data) {
        utils.toast(this, 'Error', 'No response from server')
        return
      }
      if (response.data.error) utils.toast(this, 'Error', response.data.error)
    }    

  }
}

/*********************************************/
/* Access the user's accounts (per EIP-1102) */
/*********************************************/

// You should only attempt to request the user's accounts in response to user interaction, such as a button click.
// Otherwise, you popup-spam the user like it's 1999.
// If you fail to retrieve the user's account(s), you should encourage the user to initiate the attempt.
export async function connect() {
  try {
    ethereum = await getEthereum()

    // debugging output
    if (store.state.devmode) {
      console.log(ethereum)
      if (ethereum.providers) {
        console.log(`updateProvider() found ${ethereum.providers.length} providers:`)
        for (const provider of ethereum.providers) console.log(provider)
      }
    }  

    if (!ethereum) return event.$emit(MSGS.NOT_CONNECTED)
    const accounts: string[] = await ethereum.request({ method: 'eth_requestAccounts' })
    if (store.state.devmode) console.log(`eth_requestAccounts: ${accounts.join(',')}`)    
    await handleAccountsChanged(accounts)
    await event.$emit(MSGS.ACCOUNT_CHANGED)
  } catch (err) {
    if (err.code === 4001) {
      // EIP-1193 userRejectedRequest error
      // If this happens, the user rejected the connection request.
      if (store.state.devmode) console.log('Please connect to Ethereum wallet')
      event.$emit(MSGS.NOT_READY, err)
    } else {
      if (store.state.devmode) console.error('Error requesting Ethereum connection/accounts', err)
      event.$emit(MSGS.NOT_READY, err)
    }
  }
}

// stop interval looking for ethereum provider changes
export async function stopWatchProvider() {
  if (providerInterval) clearInterval(providerInterval)
  providerInterval = null
}

export function niceAddress(address: string): string {
  if (!address) return null
  if (address.includes('…')) return address
  if (!address.startsWith('0x')) return address
  const len: number = address.length
  if (len < 10) return address
  return `${address.substring(0, 6)}…${address.substring(len - 4, len)}`
}

export function isOnMainNet(): boolean {
  return chainId === '0x1'
}

// not sure why you'd call getWalletAddress() since this is faster
export function getCurrentAccount(): string {
  return currentAccount
}

// start ethereum provider checker
startProviderWatcher()

