// Wrapper around things like segment, GA, Amplitude, etc...
// The reason I created this is that for projects where Segment's per-MTU pricing is cost-prohibitive, we can save money by doing direct calls to providers
// TODO: If I want I could create a cloud service like Segment

import { NFTUser, emailTypes } from './NFTUser'
import * as amplitude from 'amplitude-js';
import * as telegram from './telegram';
import { Vue } from 'vue-property-decorator';

let DEBUGGING: boolean = process.env.NODE_ENV === 'development'
export function _setDebugging(d: boolean): void { DEBUGGING = d }

let vue: Vue = null

const USE_SEGMENT: boolean = false                // This is the cleanest method, but my eBay project will have too many MTUs and that will make Segment cost prohibitive.
const USE_GA: boolean = false                      // Done using https://matteo-gabriele.gitbook.io/vue-gtag/
const USE_AMPLITUDE: boolean = false              // Done using https://www.npmjs.com/package/amplitude-js
const USE_HUBSPOT: boolean = false                // No. Don't really need to do hubspot from client side
const USE_TELEGRAM: boolean = true                // Done using my own client-side telegram.ts that makes API call to our /api/misc/telegram which in turn uses telegram.ts on server side

// get AMPLITUDE_API_KEY from env
if (USE_AMPLITUDE) {
  const AMPLITUDE_API_KEY: string = process.env.VUE_APP_AMPLITUDE_API_KEY
  if (!AMPLITUDE_API_KEY) {
    const e = 'AMPLITUDE_API_KEY is null from environment (maybe you need to do source .env first?)'
    throw (e)
  }

  amplitude.getInstance().init(AMPLITUDE_API_KEY, null, { includeReferrer: true, includeUtm: true, includeGclid: true });
  amplitude.getInstance().setVersionName('Web');

}

export function setVue(v: Vue) {
  vue = v
}

export function identify(user: NFTUser) {
  if (!user) return
  if (USE_SEGMENT) {
    (window as any).analytics.identify(user.getIdAsString(), user.getTraitObjectForSegment())
  } else {
    if (USE_AMPLITUDE) {
      const ampIdentify: amplitude.Identify = new amplitude.Identify()
      ampIdentify.set('user_id', user.getIdAsString())
      const traitObjectsForSegment: any = user.getTraitObjectForSegment()
      const keys: string[] = Object.keys(traitObjectsForSegment)
      for (const key of keys) {
        const value: any = traitObjectsForSegment[key]
        ampIdentify.set(key, value)
      }
      amplitude.getInstance().identify(ampIdentify)
    }
  }
}

export function alias(user: NFTUser) {
  if (!user) return
  if (USE_SEGMENT) {
    (window as any).analytics.alias(user.getIdAsString())
  } else {
    if (USE_AMPLITUDE) {
      amplitude.getInstance().setUserId(user.getIdAsString())
    }
  }
}

export function track(eventName: string, eventProperties: any) {
  if (USE_SEGMENT) {
    (window as any).analytics.track(eventName, eventProperties)
  } else {
    if (USE_AMPLITUDE) {
      amplitude.getInstance().logEvent(eventName, eventProperties)
    }
    if (USE_GA) {
      vue.$gtag.event(eventName, { event_category: 'All '})   // we use All for category cuz that's what Segment does
    }
    if (USE_TELEGRAM) {
      const user: NFTUser = vue.$store.getters.userObject;

      let msg: string = user ? user.email + ' - ' : ''
      let extraLogging: string = ''

      if (eventName.includes('LinkOut')) {
        extraLogging = ' - ' + eventProperties.url
      }

      msg += `${eventName}` + extraLogging;
      telegram.sendGroup(msg)
    }
  }
}

export function page(pageName: string) {
  if (USE_SEGMENT) {
    (window as any).analytics.page(pageName)
  } else {
    if (USE_AMPLITUDE) {
      amplitude.getInstance().logEvent(`Viewed ${pageName} Page`)     // mimic how Segment translates page views to Amplitude event names
    }
    if (USE_GA) {
      // Handle automatically by passing router into VueGtag in main.ts
      // vue.$gtag.pageview(pageName)
    }
  }
}

export function reset() {
  if (USE_SEGMENT) {
    (window as any).analytics.reset()
  } else {
    if (USE_AMPLITUDE) {
      amplitude.getInstance().clearUserProperties()
    }
  }
}


(async () => {
})();
